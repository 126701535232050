import React from "react";
import "./SearchResults.css";
import Result from "./searchresult/Result";

const SearchResults = ({ results = [], title }) => {
    return (
        <div className="search-results-container">
            <div className="gallery-title">{title}</div>
            {results.map((result) => {
                return <Result result={result} />;
            })}
            <Result newResource={true} resourceName={"ingredient"} />
        </div>
    );
};

export default SearchResults;
