import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  createIngredient,
  getIngredient,
  updateIngredient,
} from "../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";
import WizardPage from "../wizard/WizardPage";
import WizardCard from "../wizard/WizardCard";
import WizardTextField from "../wizard/WizardTextField";
import WizardTextView from "../wizard/WizardTextView";
import WizardDropDown from "../wizard/WizardDropDown";
import { INGREDIENT_CATEGORIES_DROPDOWN_OPTIONS } from "../general/Constant";
import { useNotificationContext } from "../../contexts/NotificationContext";

const IngredientCreatePage = ({ match }) => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [data, setData] = useState({ name: "", description: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [ingredientId, setIngredientId] = useState(match.params.id);
  const [saving, setSaving] = useState(false);
  const [ingredient, setIngredient] = useState({});
  const { addNotification } = useNotificationContext();

  useEffect(() => {
    if (ingredient) {
      setData({
        name: ingredient?.name || "",
        description: ingredient?.description || "",
        category: ingredient?.category || "",
      });
    }
  }, [ingredient]);

  useEffect(() => {
    const instantiateIngredient = async () => {
      const token = await getAccessTokenSilently();
      const response = await getIngredient(token, ingredientId);
      if (response.status === 200) {
        setIngredient(response.data.ingredient);
      }
    };

    if (ingredientId) {
      instantiateIngredient();
    }
  }, []);

  const createIngredientResource = async (token, ingredient) => {
    const response = await createIngredient(token, ingredient);

    if (response.status === 201) {
      const ingredientId = response.data.ingredient.id;

      history.push("/ingredient/" + ingredientId);
      addNotification({
        message: "Ingredient created successfully.",
      });
    } else {
      setErrorMessage(response.data.error);
      addNotification({
        message: "Ingredient creation failed.",
      });
    }
  };

  const editProductResource = async (token, ingredient) => {
    const response = await updateIngredient(token, ingredient);

    if (response.status === 200) {
      const ingredientId = response.data.ingredient.id;
      addNotification({
        message: "Ingredient updated successfully.",
      });
      history.push("/ingredient/" + ingredientId);
    } else {
      setErrorMessage(response.data.error);
      addNotification({
        message: "Ingredient update failed.",
      });
    }
  };

  const saveIngredient = async () => {
    if (saving) return;
    setSaving(true);
    const token = await getAccessTokenSilently();
    const ingredientPayload = {
      id: ingredientId || "",
      name: data.name,
      description: data.description,
      category: data.category,
    };

    if (ingredientId) {
      editProductResource(token, ingredientPayload);
    } else {
      createIngredientResource(token, ingredientPayload);
    }

    setSaving(false);
  };

  const getPageTitle = () => {
    return ingredientId ? "Edit Ingredient" : "Create Ingredient";
  };

  return (
    <WizardPage title={getPageTitle()} data={data} setData={setData}>
      <WizardCard
        title="Details"
        setData={setData}
        hasRequirements={data?.name?.trim() && data?.category?.trim()}
      >
        <WizardTextField
          title="Name"
          placeholder="Flour"
          value={data.name}
          onChange={(e) => {
            setData({ ...data, name: e.target.value });
          }}
          required={true}
          max={100}
        />
        <WizardTextField
          title="Description"
          placeholder="Used for baking"
          value={data.description}
          onChange={(e) => {
            setData({
              ...data,
              description: e.target.value,
            });
          }}
          required={false}
        />
        <WizardDropDown
          title="Category"
          placeholder="Select Category"
          required={true}
          options={INGREDIENT_CATEGORIES_DROPDOWN_OPTIONS}
          setValue={(value) => {
            setData({ ...data, category: value });
          }}
          value={data.category}
          hasRequirements={data.category}
          search={false}
        />
      </WizardCard>
      <WizardCard
        title="Confirmation"
        finish={saveIngredient}
        hasRequirements={true}
        errorMessage={errorMessage}
      >
        <WizardTextView title="Name" value={data.name} />
        <WizardTextView title="Description" value={data.description} />
        <WizardTextView title="Category" value={data.category} />
      </WizardCard>
    </WizardPage>
  );
};

export default IngredientCreatePage;
