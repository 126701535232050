import React from "react";
import WizardBundle from "./WizardBundle";
import WizardBundleTitle from "./WizardBundleTitle";

const WizardBundleList = ({ bundles, removeBundle }) => {
    return (
        <>
            <div style={{ textAlign: "center" }}>
                {bundles?.length > 0 ? (
                    <>
                        <WizardBundleTitle />
                        {bundles.map((bundle, index) => {
                            return (
                                <WizardBundle
                                    bundle={bundle}
                                    index={index}
                                    removeBundle={removeBundle}
                                />
                            );
                        })}
                    </>
                ) : (
                    <span style={{ color: "grey" }}>
                        No ingredients have been added yet.
                    </span>
                )}
            </div>
        </>
    );
};

export default WizardBundleList;
