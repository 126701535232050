import React from "react";

const NutritionSquare = ({ name, color, value }) => {
    return (
        <div
            style={{
                width: "30px",
                height: "30px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "3px",
                borderColor: `rgba(${color}, 1)`,
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div
                style={{
                    fontSize: "9px",
                    color: "grey",
                    position: "relative",
                    top: "-2px",
                    height: "15px",
                }}
            >
                {name}
            </div>
            <div
                style={{
                    fontSize: "10px",
                    position: "relative",
                    top: "-4px",
                    height: "15px",
                }}
            >
                {value}
            </div>
        </div>
    );
};

export default NutritionSquare;
