import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import BackupView from "../../components/BackupView";
import { getUserPreferences, setUserPreferences } from "../../api/OtterApi";

const UserPreferencesPage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [preferences, setPreferences] = useState();
  const [flow, setFlow] = useState("loading");
  const [defaultMultiplier, setDefaultMultiplier] = useState();
  const [message, setMessage] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    const instantiatePreferences = async () => {
      const token = await getAccessTokenSilently();
      const response = await getUserPreferences(token);
      if (response.status === 200) {
        setPreferences(response.data.preferences);
        setFlow("success");
      } else {
        console.error(response.data.error);
        setFlow("error");
      }
    };
    instantiatePreferences();
  }, []);

  useEffect(() => {
    if (preferences) {
      setDefaultMultiplier(preferences.default_multiplier);
    }
  }, [preferences]);

  const saveUserPreferences = async () => {
    if (!canSave || invalid) return;

    const token = await getAccessTokenSilently();
    const basePreferences = { ...preferences };
    basePreferences.default_multiplier = defaultMultiplier;
    const response = await setUserPreferences(token, basePreferences);
    if (response.status === 200) {
      setMessage("Successfully saved your preferences.");
      setInvalid(false);
      setPreferences(response.data.preferences);
    } else {
      console.error(response.data.error);
      setInvalid(true);
      setMessage(
        "Something went wrong while saving, please refresh and try again."
      );
    }
    setCanSave(false);
  };

  const displayMessage = () => {
    const messageStyle = {
      paddingTop: "1rem",
      fontSize: "small",
      color: "grey",
      marginBottom: "1rem",
    };

    if (invalid) {
      messageStyle.color = "red";
    }

    return (
      <div style={messageStyle}>
        <p>{message}</p>
      </div>
    );
  };

  const processInput = (newValue) => {
    if (newValue > 30) {
      newValue = 30;
    }

    if (newValue < 1) {
      newValue = 1;
    }

    setDefaultMultiplier(newValue);

    if (newValue === preferences.default_multiplier) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
    setInvalid(false);
    setMessage("");
  };

  return (
    <BackupView error={flow === "error"} loading={flow === "loading"}>
      <h4>Update Your Preferences</h4>
      <div
        className="preference-item-container"
        style={{ paddingBottom: "1rem" }}
      >
        <label style={{ paddingRight: "0.5rem" }}>Default Multiplier</label>
        <input
          label="Default Multiplier"
          type="number"
          value={defaultMultiplier}
          step="1"
          onChange={(e) => {
            processInput(parseFloat(e.target.value));
          }}
        />
      </div>
      <div
        className="save-preferences-button"
        style={{
          margin: "auto",
          fontWeight: "bold",
          color: canSave ? "blue" : "lightgrey",
          width: "50px",
        }}
        onClick={() => {
          saveUserPreferences();
        }}
      >
        Save
      </div>
      {displayMessage()}
    </BackupView>
  );
};

export default UserPreferencesPage;
