import React, { useState, useEffect } from "react";
import DetailPage from "../general/DetailPage";
import ProductDetails from "./ProductDetails";
import { getProduct, deleteProduct } from "../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";
import ModifierButtons from "../../components/ModifierButtons";
import { Loader } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

const ProductDetailPage = ({ match }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [product, setProduct] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const history = useHistory();

    const onEdit = async () => {
        history.push("edit/" + match.params.id);
    };

    const onDelete = async () => {
        const token = await getAccessTokenSilently();
        setIsDeleting(true);
        const response = await deleteProduct(token, match.params.id);
        if (response.status === 200) {
            history.push("/product");
        } else {
            console.error(response);
        }
        setIsDeleting(false);
    };

    useEffect(() => {
        const initializeProduct = async () => {
            const token = await getAccessTokenSilently();
            setIsLoading(true);
            const response = await getProduct(token, match.params.id);

            if (response.status === 200) {
                setProduct(response.data.product);
                setIsEditable(response.data.editable);
            } else {
                console.error(response);
            }
            setIsLoading(false);
        };
        initializeProduct();
    }, []);

    return (
        <div>
            <DetailPage>
                {isLoading ? (
                    <Loader active size="medium">
                        Loading
                    </Loader>
                ) : (
                    <>
                        <ProductDetails product={product} />
                        {isEditable ? (
                            <ModifierButtons
                                onEdit={onEdit}
                                onDelete={onDelete}
                                isDeleting={isDeleting}
                                resourceId={match.params.id}
                            />
                        ) : null}
                    </>
                )}
            </DetailPage>
        </div>
    );
};

export default ProductDetailPage;
