import React from "react";
import * as GiIcons from "react-icons/gi";

import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as GoIcons from "react-icons/go";

export const SidebarData = [
    {
        title: "Dashboard",
        path: "/dashboard",
        icon: <RiIcons.RiDashboardFill />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        active: true,
    },
    {
        title: "Nutrition",
        path: "#",
        icon: <GiIcons.GiShinyApple />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        active: false,
        subNav: [
            {
                title: "Meal Plans",
                path: "/mealplan",
                icon: <GoIcons.GoCalendar />,
            },
            {
                title: "Meals",
                path: "/meal",
                icon: <GiIcons.GiMeal />,
            },
            {
                title: "Ingredients",
                path: "/ingredient",
                icon: <FaIcons.FaCarrot />,
            },
            {
                title: "Products",
                path: "/product",
                icon: <FaIcons.FaBarcode />,
            },
        ],
    },
];
