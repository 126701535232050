import React, { useState, useEffect } from "react";
import WizardCard from "../../../wizard/WizardCard";
import WizardTextView from "../../../wizard/WizardTextView";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import {
    createMealPlanPreference,
    updateMealPlanPreference,
} from "../../../../api/OtterApi";

const MealPlanPrefConfirmCard = ({
    back,
    forward,
    isFirst,
    wizData,
    isEdit,
}) => {
    const { getAccessTokenSilently } = useAuth0();
    const history = useHistory();
    const [saving, setSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const saveMealPlanPreference = async () => {
        if (saving) return;
        setSaving(true);

        const token = await getAccessTokenSilently();

        var response;
        if (isEdit) {
            response = await updateMealPlanPreference(token, wizData);
        } else {
            response = await createMealPlanPreference(token, wizData);
        }

        if (response.status === 201 || response.status === 200) {
            history.push("/mealplan/" + wizData.mealplan_id);
        } else {
            setErrorMessage(response.data.error);
        }

        setSaving(false);
    };

    return (
        <WizardCard
            title="Confirm Meal Plan Preference"
            finish={saveMealPlanPreference}
            errorMessage={errorMessage}
            back={back}
            forward={forward}
            isFirst={isFirst}
        >
            <div>
                <h4>Details</h4>
            </div>
            <WizardTextView title="Name" value={wizData.name} />
        </WizardCard>
    );
};

export default MealPlanPrefConfirmCard;
