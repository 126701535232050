import React from "react";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import * as FiIcons from "react-icons/fi";

const PreferenceSelector = ({
    preferences,
    selectedPreference,
    setSelectedPreference,
    createPath,
    deletePreference,
    editPreference,
    createPreference,
}) => {
    return (
        <div
            className="meal-detail-customization-container"
            style={{
                height: "fit-content",
                width: "100%",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "5px",
                borderColor: "lightgrey",
                margin: "10px 0px",
                padding: "10px",
            }}
        >
            <div
                style={{
                    display: "flex",
                }}
            >
                <div style={{ marginRight: "15px" }}>
                    <h4
                        className="ui header"
                        style={{
                            textAlign: "left",
                            marginBottom: "10px",
                        }}
                    >
                        Current Preference:
                        <span
                            style={{
                                marginLeft: "10px",
                                color: "grey",
                            }}
                        >
                            {selectedPreference?.name || (
                                <div className="ui tiny active inline loader"></div>
                            )}
                        </span>
                    </h4>
                </div>
                {/* TODO: Added "canEdit" to preferences to determine if they can be editted */}
                {/* {selectedPreference.canEdit && ( */}
                <>
                    {editPreference && (
                        <div
                            style={{ marginRight: "5px" }}
                            onClick={() => {
                                editPreference(selectedPreference);
                            }}
                        >
                            <FiIcons.FiEdit2 />
                        </div>
                    )}
                    <div
                        onClick={() => {
                            deletePreference(selectedPreference);
                        }}
                    >
                        <FiIcons.FiX />
                    </div>
                </>
                {/* )} */}
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    flexDirection: "row",
                }}
            >
                {preferences
                    ?.sort((a, b) => {
                        if (a.name?.toLowerCase() === "default") {
                            return -1;
                        } else if (b.name?.toLowerCase() === "default") {
                            return 1;
                        } else {
                            return (
                                a.name?.toLowerCase() < b.name?.toLowerCase()
                            );
                        }
                    })
                    .map((preference) => {
                        var semanticStyle =
                            selectedPreference?.name === preference?.name
                                ? "blue"
                                : preference?.name === "Default"
                                ? "grey basic"
                                : "primary";
                        return (
                            <div
                                className={`ui ${semanticStyle} label`}
                                style={{
                                    marginTop: "5px",
                                    minWidth: "50px",
                                }}
                                onClick={() => {
                                    setSelectedPreference(preference);
                                }}
                            >
                                {preference.name}
                            </div>
                        );
                    })}
                {createPath ? (
                    <div
                        className="ui primary label"
                        style={{
                            marginTop: "5px",
                            minWidth: "50px",
                        }}
                    >
                        <Link to={createPath}>
                            <AiIcons.AiOutlinePlus />
                        </Link>
                    </div>
                ) : null}
                {createPreference ? (
                    <div
                        className="ui primary label"
                        style={{
                            marginTop: "auto",
                            minWidth: "50px",
                        }}
                        onClick={() => {
                            createPreference();
                        }}
                    >
                        <AiIcons.AiOutlinePlus />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default PreferenceSelector;
