// This function is used to map the products list from the
// backend api to a list of drop down menu options
export const getProductDropdownOptions = (products) => {
  return products.map((product) => {
    return {
      key: product.id,
      value: product,
      label: product.name,
    };
  });
};
