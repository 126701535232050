import React, { useState, useEffect, useRef } from "react";

const SearchableDropdown = ({
  options,
  selected,
  onSelectedChange,
  label,
  setSearchQuery,
}) => {
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const ref = useRef();

  useEffect(() => {
    setSearchQuery && setSearchQuery(searchText);
  }, [searchText, setSearchQuery]);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (
        ref.current.contains(event.target) ||
        event.target.className === "search-text"
      ) {
        return;
      }

      setOpen(false);
    };

    document.body.addEventListener("click", onBodyClick);

    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);

  const renderedOptions = options.map((option) => {
    if (option?.name !== "None" && option?.value?.id === selected?.value?.id) {
      return null;
    }

    return (
      <div
        key={option?.value?.id || null}
        className="item"
        onClick={() => {
          onSelectedChange(option);
        }}
      >
        {option.label}
      </div>
    );
  });

  return (
    <div ref={ref} className="ui form">
      <div className="field">
        <label className="label">{label}</label>
        <div
          onClick={(e) => {
            if (e.target.className !== "search-text") {
              setOpen(!open);
            }
          }}
          className={`ui selection dropdown ${open ? "visible active" : ""}`}
        >
          <i className="dropdown icon"></i>
          {open ? (
            <input
              className="search-text"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          ) : null}
          <div className="text">
            {open ? <br /> : null}
            {open ? "[Selected]:" : ""} {selected?.label}
          </div>
          <div className={`menu ${open ? "visible transition" : ""}`}>
            {renderedOptions}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchableDropdown;
