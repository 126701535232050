import React, { useState } from "react";
import styled from "styled-components";
import { Divider } from "semantic-ui-react";
import * as FiIcons from "react-icons/fi";

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
`;

const CardContainer = styled.div`
    max-width: 800px;
    border-width: 1px;
    border-radius: 5px;
    border-style: ${(props) => (props.border ? "solid" : "none")};
    ${(props) =>
        props.borderColor ? `border-color: ${props.borderColor};` : ""};
    margin: 5px 0px;
    text-align: left;
    background: ${(props) => (props.background ? props.background : "50px")};
    max-height: ${(props) => (props.expanded ? "300px" : "50px")};
    height: auto;
`;

const CardHeader = styled.div`
    font-size: 15px;
    font-weight: bold;
`;

const CardContent = styled.div`
    visibility: ${(props) => (props.expanded ? "visible" : "hidden")};
    opacity: ${(props) => (props.expanded ? "1" : "0")};
    transition: visibility 0s, opacity 0.5s linear;
    padding: 15px;
    padding-top: 0px;
`;

const ToggleIcon = styled.div`
    padding-top: 2px;
`;

const CollapsibleCard = ({
    children,
    title,
    background,
    border,
    borderColor,
}) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <CardContainer
            expanded={expanded}
            background={background}
            border={border}
            borderColor={borderColor}
        >
            <Row expanded={expanded} onClick={() => setExpanded(!expanded)}>
                <CardHeader>{title}</CardHeader>
                <ToggleIcon>
                    {expanded ? (
                        <FiIcons.FiChevronUp />
                    ) : (
                        <FiIcons.FiChevronDown />
                    )}
                </ToggleIcon>
            </Row>

            <CardContent expanded={expanded}>
                <Divider style={{ marginTop: "0px" }} />
                {children}
            </CardContent>
        </CardContainer>
    );
};

export default CollapsibleCard;
