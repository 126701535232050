import React, { useState, useEffect } from "react";
import GalleryCard from "./card/GalleryCard";
import "./SearchResultGallery.css";
import { getCurrentMealPlan } from "../../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";

const SearchResultGallery = ({
    title,
    resource,
    results,
    isMealplan = false,
}) => {
    const [currentMealplanId, setCurrentMealplanId] = useState();
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const initializeCurrentMealplanId = async () => {
            const token = await getAccessTokenSilently();

            const response = await getCurrentMealPlan(token);
            if (response.status === 200) {
                setCurrentMealplanId(response.data.mealplan_id);
            } else {
                console.error(response.data.error);
            }
        };
        initializeCurrentMealplanId();
    }, []);

    return (
        <div className="gallery-container">
            <div className="gallery-title">{title}</div>
            <div className="gallery-cards-container">
                {results.map((result) => {
                    return (
                        <GalleryCard
                            result={result}
                            currentMealplanId={currentMealplanId}
                            isMealplan={isMealplan}
                        />
                    );
                })}
                <GalleryCard newResource={true} resourceName={resource} />
            </div>
        </div>
    );
};

export default SearchResultGallery;
