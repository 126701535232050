import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";

const SidebarLink = styled(Link)`
    display: flex;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 60px;
    text-decoration: none;
    font-size: 18px;
    background: #15171c;
    &:hover {
        background: #252831;
        border-left: 4px solid #7393b3;
        cursor: pointer;
        color: white;
    }
`;

const SidebarLabel = styled.span`
    margin-left: 16px;
`;

const DropdownLink = styled(Link)`
    background: #414757;
    height: 60px;
    padding-left: 3rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    &:hover {
        background: #7393b3;
        cursor: pointer;
        color: white;
    }
`;

const SubMenu = ({ item }) => {
    const [subnav, setSubnav] = useState(false);
    const showSubnav = () => setSubnav(!subnav);

    return (
        <>
            <IconContext.Provider value={{ color: "#fff" }}>
                <SidebarLink
                    to={item.path}
                    onClick={(e) => {
                        if (!item.active) {
                            e.preventDefault();
                        }
                        if (item.subNav) {
                            showSubnav();
                        }
                    }}
                >
                    <div>
                        {item.icon}
                        <SidebarLabel>{item.title}</SidebarLabel>
                    </div>
                    <div>
                        {item.subNav && subnav
                            ? item.iconOpened
                            : item.subNav
                            ? item.iconClosed
                            : null}
                    </div>
                </SidebarLink>
                {subnav &&
                    item.subNav.map((item, index) => {
                        return (
                            <DropdownLink to={item.path} key={index}>
                                {item.icon}
                                <SidebarLabel>{item.title}</SidebarLabel>
                            </DropdownLink>
                        );
                    })}
            </IconContext.Provider>
        </>
    );
};

export default SubMenu;
