import React from "react";

const IngredientDetails = ({ ingredient }) => {
    return (
        <div>
            {ingredient ? (
                <div
                    style={{
                        maxWidth: "600px",
                        width: "fit-content",
                        margin: "auto",
                        marginTop: "2rem",
                        display: "flex",
                        flexWrap: "wrap-reverse",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            maxWidth: "400px",
                            textAlign: "left",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "xx-large",
                                    lineHeight: "150%",
                                }}
                            >
                                {ingredient.name}
                            </div>
                        </div>
                        <div
                            style={{
                                fontSize: "large",
                                color: "lightgrey",
                                marginTop: "1rem",
                            }}
                        >
                            {ingredient.description}
                        </div>
                        {ingredient?.category && (
                            <div
                                style={{
                                    fontSize: "large",
                                    color: "lightgrey",
                                    marginTop: "1rem",
                                }}
                            >
                                Category: {ingredient.category}
                            </div>
                        )}
                    </div>
                    <div
                        style={{
                            maxWidth: "400px",
                            minWidth: "300px",
                            marginTop: "1rem",
                            // backgroundColor: "red",
                            paddingBottom: "2rem",
                        }}
                    >
                        <div
                            style={{
                                borderRadius: "4px",
                                borderColor: "lightgrey",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                height: "200px",
                                width: "200px",
                                backgroundImage: `url(https://picsum.photos/200/200?blur=10&random=${
                                    Math.floor(Math.random() * 1000) + 1
                                })`,
                                margin: "auto",
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div style={{ color: "red" }}>
                    There was a problem loading this ingredient, please try
                    again soon.
                </div>
            )}
        </div>
    );
};

export default IngredientDetails;
