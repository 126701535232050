import React from "react";
import "./GalleryCard.css";
import { useHistory } from "react-router-dom";
import * as FaIcons from "react-icons/fa";

const GalleryCard = ({
  result,
  newResource = false,
  resourceName = "resource",
  currentMealplanId,
  isMealplan = false,
}) => {
  const history = useHistory();

  const getVisibilityIcon = () => {
    var iconStyle = {
      fontSize: "x-large",
      color: "white",
      margin: "10px",
      marginTop: "7px",
    };
    return result?.is_public ? (
      <FaIcons.FaUsers style={iconStyle} />
    ) : (
      <FaIcons.FaUserLock style={iconStyle} />
    );
  };

  const getCurrentMealPlanIcon = () => {
    var iconStyle = {
      fontSize: "x-large",
      color: "white",
      margin: "10px",
      marginTop: "7px",
    };
    return currentMealplanId === result.id ? (
      // <IoIcons.IoIosStar style={iconStyle} />
      <FaIcons.FaCalendarCheck style={iconStyle} />
    ) : (
      <></>
      // We may want to have the star outline if we decide to click on it to make it current
      // <IoIcons.IoIosStarOutline style={iconStyle} />
    );
  };

  if (newResource) {
    var imageStyle = {
      borderStyle: `solid`,
      borderColor: "lightgrey",
      borderWidth: "20px",
      backgroundColor: `white`,
      width: `100%`,
      height: `225px`,
      borderRadius: `5px`,
    };
    var plusStyle = {
      color: "lightgrey",
      fontSize: "150px",
      position: "relative",
      top: "85px",
    };
    return (
      <div
        className="gallery-card-container"
        onClick={() => {
          history.push(history.location.pathname + "/create");
        }}
      >
        <div className="gallery-new-card-container" style={imageStyle}>
          <div className="gallery-new-card-plus" style={plusStyle}>
            +
          </div>
        </div>
        <div className="gallery-new-card-name">Create a new {resourceName}</div>
      </div>
    );
  } else {
    return (
      <div
        className="gallery-card-container"
        onClick={() => {
          history.push(history.location.pathname + "/" + result.id);
        }}
      >
        <div className="gallery-card-image-container" style={imageStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              width: "100%",
              height: "40px",
            }}
          >
            {getVisibilityIcon()}
            {isMealplan ? getCurrentMealPlanIcon() : null}
          </div>
        </div>
        <div className="gallery-card-name">{result.name}</div>
        <div className="gallery-card-description">{result.description}</div>
      </div>
    );
  }
};

export default GalleryCard;
