import React from "react";

const WizardBundleTitle = () => {
    return (
        <div
            // class="ui image label"
            style={{
                display: "flex",
                flexDirection: "row",
                // margin: "10px auto",
                // backgroundColor: "red",
            }}
        >
            <div
                style={{
                    // backgroundColor: "red",
                    flexGrow: 1,
                    width: "100%",
                    // borderStyle: "solid",
                    // borderWidth: "1px",
                    // borderRadius: "3px",
                    // margin: "10px auto",
                    // marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "row",
                    textAlign: "center",
                }}
            >
                <div style={{ width: "120px", color: "grey" }}>Quantity</div>
                <div style={{ width: "120px", color: "grey" }}>Units</div>
                <div style={{ width: "120px", color: "grey" }}>Ingredient</div>
                {/* <div style={{ width: "150px", color: "grey" }}>
                    Alternatives
                </div> */}
                <div style={{ width: "50px" }}></div>
            </div>
        </div>
    );
};

export default WizardBundleTitle;
