import React from "react";
import WizardTextView from "./WizardTextView";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

const WizardBundle = ({ bundle, index, removeBundle }) => {
    return (
        <div
            class="ui image label"
            style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px auto",
                // backgroundColor: "red",
            }}
        >
            <div
                style={{
                    // backgroundColor: "red",

                    width: "100%",
                    // borderStyle: "solid",
                    // borderWidth: "1px",
                    // borderRadius: "3px",
                    // margin: "10px auto",
                    // marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        width: "100px",
                        textAlign: "center",
                        margin: "auto",
                    }}
                >
                    {bundle.quantity}
                </div>
                <div
                    style={{
                        width: "100px",
                        textAlign: "center",
                        margin: "auto",
                    }}
                >
                    {bundle.units}
                </div>
                <div
                    style={{
                        width: "100px",
                        textAlign: "center",
                        margin: "auto",
                    }}
                >
                    <Link to={`/ingredient/${bundle.ingredient.id}`}>
                        {bundle.ingredient.name}
                    </Link>
                </div>
                {/* <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        // padding: "5px",
                        flexGrow: 1,
                        width: "170px",
                        textAlign: "left",
                        margin: "auto",
                        paddingLeft: "40px",
                    }}
                >
                    {bundle?.alternatives?.map((ingredient, index) => {
                        return (
                            <Link to={`/ingredient/${ingredient.id}`}>
                                {ingredient.name}
                                {index === bundle.alternatives.length - 1
                                    ? ""
                                    : ","}
                            </Link>
                        );
                    })}
                </div> */}
                {removeBundle ? (
                    <div style={{ width: "30px", margin: "auto" }}>
                        <Icon
                            name="delete"
                            size="large"
                            onClick={() => {
                                removeBundle(index);
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default WizardBundle;
