import React, { useState, useEffect } from "react";
import { Divider } from "semantic-ui-react";
import WizardBundlePrefList from "../../../wizard/WizardBundlePrefList";
import WizardCard from "../../../wizard/WizardCard";
import WizardTextView from "../../../wizard/WizardTextView";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import {
  createMealPreference,
  updateMealPreference,
  getProduct,
  getIngredient,
} from "../../../../api/OtterApi";

const MealPrefConfirmCard = ({ back, forward, isFirst, wizData, isEdit }) => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [_, setIngredients] = useState([]);

  useEffect(() => {
    const instantiate = async () => {
      const token = await getAccessTokenSilently();

      const ingredientPromises = wizData.bundle_prefs.map((bundlePref) => {
        return getIngredient(token, bundlePref.ingredient_id);
      });

      Promise.all(ingredientPromises).then((results) => {
        setIngredients(
          results
            .map((result) => {
              return result.status === 200 ? result.data.ingredient : null;
            })
            .filter((ingredient) => ingredient !== null)
        );
      });
    };
    if (wizData?.bundle_prefs.length > 0) {
      instantiate();
    }
  }, []);

  const saveMealPreference = async () => {
    if (saving) return;
    setSaving(true);

    const token = await getAccessTokenSilently();

    // {
    //     "bundle_prefs": [
    //         {
    //             "id": "ad4aa259-765f-4e23-b83f-1e0f01efbc5e",
    //             "ingredient_id": "fe54379e-ccf8-4eca-bc97-82e5933625aa",
    //             "quantity": 0.5,
    //             "units": "count"
    //         },
    //         {
    //             "id": "3d1f3401-7192-4c58-bbc3-6281dbcf4d89",
    //             "ingredient_id": "1d682777-bdba-47b5-bfe6-320dd2552cf2",
    //             "quantity": 1,
    //             "units": "tablespoons"
    //         }
    //     ],
    //     "suggested_servings": 4
    // }

    // Modify the entered bundles to consider the enteres servings.
    // We only want to store the bundle values for a single serving, so multiplying
    // by servings will be simple in the future.
    for (let index = 0; index < wizData.bundle_prefs.length; index++) {
      const bundle = wizData.bundle_prefs[index];
      bundle.quantity = bundle.quantity / wizData.suggested_servings;
    }

    var response;
    if (isEdit) {
      response = await updateMealPreference(token, wizData);
    } else {
      response = await createMealPreference(token, wizData);
    }

    if (response.status === 201 || response.status === 200) {
      history.push("/meal/" + wizData.meal_id);
    } else {
      setErrorMessage(response.data.error);
    }

    setSaving(false);
  };

  return (
    <WizardCard
      title="Confirm Meal Preference"
      finish={saveMealPreference}
      errorMessage={errorMessage}
      back={back}
      forward={forward}
      isFirst={isFirst}
    >
      <div>
        <h4>Details</h4>
      </div>
      <WizardTextView title="Name" value={wizData.name} />
      <WizardTextView title="Servings" value={wizData.suggested_servings} />
      <Divider />
      <div>
        <h4>Ingredients</h4>
      </div>
      <div
        style={{
          marginTop: "20px",
          padding: "10px",
        }}
      >
        <WizardBundlePrefList bundlePrefs={wizData?.bundle_prefs || []} />
      </div>
    </WizardCard>
  );
};

export default MealPrefConfirmCard;
