import React, { useState, useEffect } from "react";
// import ResourceList from "../../components/resourcelist/ResourceList";
import { listIngredients } from "../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";
import SearchBar from "../../components/searchbar/SearchBar";
import SearchResults from "../../components/searchbar/list/SearchResults";
import "./IngredientPage.css";
import * as GrIcons from "react-icons/gr";

const IngredientPage = () => {
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [ingredients, setIngredients] = useState([]);
    const [hasNext, setHasNext] = useState(false);
    const [searchText, setSearchText] = useState("");
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const getIngredients = async () => {
            const token = await getAccessTokenSilently();
            const response = await listIngredients(
                token,
                limit,
                offset,
                searchText.trim()
            );
            if (response.status === 200) {
                const rawIngredients = response.data.ingredients;
                if (rawIngredients.length === 0) {
                    setOffset(0);
                } else {
                    setIngredients(rawIngredients);
                }
            } else {
                console.error(response.data.error);
            }
        };
        getIngredients();
    }, [searchText, limit, offset]);

    useEffect(() => {
        const checkHasNext = async () => {
            const token = await getAccessTokenSilently();
            const response = await listIngredients(
                token,
                1,
                offset + limit + 1,
                searchText.trim()
            );
            if (response.status === 200) {
                const rawIngredients = response.data.ingredients;
                setHasNext(rawIngredients && rawIngredients.length > 0);
            } else {
                console.error(response.data.error);
            }
        };
        checkHasNext();
    }, [ingredients]);

    const handlePrevClick = () => {
        setOffset(Math.max(0, offset - limit));
    };

    const handleNextClick = () => {
        setOffset(offset + limit);
    };

    return (
        <div className="ingredient-page-container">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />
            <SearchResults title={"Ingredients"} results={ingredients} />
            <div className="ingredient-search-page-controls">
                <div className="page-prev-button" onClick={handlePrevClick}>
                    {offset > 0 ? <GrIcons.GrPrevious /> : ""}
                </div>
                <div className="page-next-button" onClick={handleNextClick}>
                    {hasNext ? <GrIcons.GrNext /> : ""}
                </div>
            </div>
        </div>
    );
};

export default IngredientPage;
