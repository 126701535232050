import React, { useState } from "react";
import "./Nav.css";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import AuthenticationButton from "./authentication-button";
import SafeLink from "./components/SafeLink";
import * as FaIcons from "react-icons/fa";
import { SidebarData } from "./components/SidebarData";
import SubMenu from "./components/SubMenu";
import styled from "styled-components";
import { IconContext } from "react-icons/lib";
import OutsideAlerter from "./components/OutsideAlerter";
import { isMobile } from "react-device-detect";

const HomeNavLink = styled(SafeLink)`
  ${"" /* background: red; */}
  margin-left: ${({ sidebar }) => (sidebar ? "-100%" : "0%")};
  transition: 300ms;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  font-size: large;
  height: fit-content;
  margin: auto 0;
  color: ${({ sidebar }) => (sidebar ? "rgba(0, 0, 0, 0)" : "white")};
  &:hover {
    color: ${({ sidebar }) => (sidebar ? "rgba(0, 0, 0, 0)" : "lightgrey")};
  }
`;

const NavIcon = styled.div`
  margin: 0px ${isMobile ? "10px" : "2rem"};
  font-size: 1rem;
  height: 48px;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #15171c;
  width: 250px;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 500ms;
  z-index: 10;
  padding: 0px;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const NavBarLeft = styled.div`
  display: flex;
  flex-direction: row;
`;
const NavBarRight = styled.div`
  display: flex;
  flex-direction: row;
`;

const NavBar = styled.div`
  background: rgb(115, 147, 179);
  height: 48px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const LogoDiv = styled.div`
  line-height: 48px;
  font-size: ${isMobile ? "large" : "large"};
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  color: white;
`;

function Nav() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(isAuthenticated);
  const closeSidebar = () => setSidebar(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <NavBar>
          <NavBarLeft>
            <NavIcon isMobile={isMobile}>
              <FaIcons.FaBars onClick={showSidebar} />
            </NavIcon>
            <HomeNavLink className="nav-home" to="/" sidebar={sidebar}>
              Life Bespoked
            </HomeNavLink>
          </NavBarLeft>
          <NavBarRight>
            <AuthenticationButton loading={isLoading} />
          </NavBarRight>
        </NavBar>
        <OutsideAlerter toggle={closeSidebar}>
          <SidebarNav sidebar={sidebar}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <LogoDiv>Life Bespoked</LogoDiv>
              <div>
                <SidebarWrap>
                  {SidebarData.map((item, index) => {
                    return <SubMenu item={item} key={index} />;
                  })}
                </SidebarWrap>
              </div>
            </div>
          </SidebarNav>
        </OutsideAlerter>
      </IconContext.Provider>
    </>
  );
}

export default Nav;
