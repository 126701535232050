export const VOLUME_UNITS_OPTIONS = [
    {
        key: "cups",
        value: "cups",
        text: "cup",
    },
    {
        key: "gallons",
        value: "gallons",
        text: "gal",
    },
    {
        key: "liters",
        value: "liters",
        text: "L",
    },
    {
        key: "milliliters",
        value: "milliliters",
        text: "ml",
    },
    {
        key: "ounces",
        value: "ounces",
        text: "fl oz",
    },
    {
        key: "pints",
        value: "pints",
        text: "pt",
    },
    {
        key: "quarts",
        value: "quarts",
        text: "qt",
    },
    {
        key: "tablespoons",
        value: "tablespoons",
        text: "tbsp",
    },
    {
        key: "teaspoons",
        value: "teaspoons",
        text: "tsp",
    },
];

export const MASS_UNITS_OPTIONS = [
    {
        key: "grams",
        value: "grams",
        text: "g",
    },
    {
        key: "kilograms",
        value: "kilograms",
        text: "kg",
    },
    {
        key: "milligrams",
        value: "milligrams",
        text: "mg",
    },
    {
        key: "ounces_weight",
        value: "ounces_weight",
        text: "oz (wt)",
    },
    {
        key: "pounds",
        value: "pounds",
        text: "lb",
    },
];

export const COUNT_UNITS_OPTIONS = [
    {
        key: "count",
        value: "count",
        text: "count",
    },
];

export const MASS_UNITS = MASS_UNITS_OPTIONS.map((option) => option.value);
export const VOLUME_UNITS = VOLUME_UNITS_OPTIONS.map((option) => option.value);
export const COUNT_UNITS = COUNT_UNITS_OPTIONS.map((option) => option.value);

export const SUPPORTED_UNITS_OPTIONS = [
    ...COUNT_UNITS_OPTIONS,
    ...VOLUME_UNITS_OPTIONS,
    ...MASS_UNITS_OPTIONS,
];

export const unitValueToText = (value) => {
    const candidate = SUPPORTED_UNITS_OPTIONS.filter(
        (option) => option.value === value.toLowerCase()
    );

    return candidate?.length >= 0 ? candidate[0].text : "UNKNOWN";
};

export const getUnitType = (unit) => {
    const lowerunit = unit?.toLowerCase() || "";
    return MASS_UNITS.includes(lowerunit)
        ? "mass"
        : VOLUME_UNITS.includes(lowerunit)
        ? "volume"
        : "other";
};

export const INGREDIENT_CATEGORIES = [
    "Bakery",
    "Baking",
    "Dairy",
    "Deli",
    "Frozen",
    "Meat & Seafood",
    "Pantry",
    "Produce",
    "Other",
];

export const INGREDIENT_CATEGORIES_DROPDOWN_OPTIONS = INGREDIENT_CATEGORIES.map(
    (category) => {
        return {
            key: category,
            value: category,
            text: category,
        };
    }
);

export const MONTH_NAMES = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const DAYS_OF_THE_WEEK = ["S", "M", "T", "W", "T", "F", "S"];

export const MAX_TILES_PER_CALENDAR_MONTH = 42;

export const DAYS_PER_WEEK = 7;
