import React from "react";
import CollapsibleCard from "../../../components/CollapsibleCard";

const InventoryNote = () => {
    return (
        <CollapsibleCard title={"What's an Inventory?"} background={"#EEEEEE"}>
            <p>
                An inventory allows you to account for ingredients you may
                already have from this shopping list.
            </p>
            <p>
                You may update the quantity and units of the ingredients below,
                and the new total you need to shop for will be reflected in the
                Shopping List tab.
            </p>
        </CollapsibleCard>
    );
};

export default InventoryNote;
