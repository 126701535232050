import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
    getMealPlanPreference,
    searchMealPlanPreferences,
} from "../../../api/OtterApi";
import WizardPage from "../../wizard/WizardPage";
import MealPlanPrefDetailsCard from "./wizard/1_MealPlanPrefDetailsCard";
import MealPlanPrefConfirmCard from "./wizard/2_MealPlanPrefConfirmCard";

const CreateMealPlanPreferencePage = ({ match }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [preferences, setPreferences] = useState();
    const [isEdit, setIsEdit] = useState(false);
    const [wizardData, setWizardData] = useState();

    useEffect(() => {
        const instantiatePreferences = async () => {
            const token = await getAccessTokenSilently();
            const response = await searchMealPlanPreferences(
                token,
                20,
                0,
                "",
                match.params.id
            );
            if (response.status === 200) {
                setPreferences(response.data.mealplanpreferences);
            } else {
                console.error(response);
            }
        };

        const instantiatePreference = async () => {};
        if (match.params.prefId) {
            setIsEdit(true);
            instantiatePreference();
        } else {
            setIsEdit(false);
            instantiatePreferences();
        }
    }, [getAccessTokenSilently, match]);

    useEffect(() => {
        const instantiateWizData = async () => {
            const token = await getAccessTokenSilently();
            const response = await getMealPlanPreference(
                token,
                match.params.prefId
            );
            if (response.status === 200) {
                const pref = response.data.mealplanpreference;
                if (pref.mealplan_id === match.params.id) {
                    setWizardData(pref);
                } else {
                    console.error(
                        "It looks like this mealplan and preference do not match."
                    );
                }
            } else {
                console.error(response);
            }
        };
        if (isEdit) {
            instantiateWizData();
        } else if (preferences) {
            // Create a default meal preference name
            const defaultPreference = preferences.filter((preference) => {
                return preference.name.toLowerCase() === "default";
            })[0];

            if (defaultPreference) {
                defaultPreference.name = `New Preference ${preferences.length}`;
                setWizardData(defaultPreference);
            } else {
                console.error("Couldn't find default mealplan.");
            }
        }
    }, [preferences, isEdit, getAccessTokenSilently, match]);

    if (!wizardData) {
        return <div>Loading...</div>;
    }

    return (
        <WizardPage
            title={`${isEdit ? "Edit" : "Create"} Meal Plan Preference`}
            data={wizardData}
            setData={setWizardData}
        >
            <MealPlanPrefDetailsCard
                wizData={wizardData}
                setWizData={setWizardData}
            />
            <MealPlanPrefConfirmCard wizData={wizardData} isEdit={isEdit} />
        </WizardPage>
    );
};

export default CreateMealPlanPreferencePage;
