import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IoCheckmark } from "react-icons/io5";
import { isMobile } from "react-device-detect";
import { useAuth0 } from "@auth0/auth0-react";
import { Dropdown } from "semantic-ui-react";
import {
  COUNT_UNITS_OPTIONS,
  MASS_UNITS_OPTIONS,
  VOLUME_UNITS_OPTIONS,
  getUnitType,
} from "../../general/Constant";
import {
  getShoppingListItem,
  updateShoppingListItemInventory,
} from "../../../api/OtterApi";

const SLCard = styled.div`
  ${({ completed, surplus }) =>
    completed
      ? "background: rgb(212, 247, 215);"
      : surplus
      ? "background: rgb(240, 240, 240);"
      : ""};
  max-width: 800px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  border-radius: 5px;
  margin: ${isMobile ? 5 : 5}px 0px;
  padding: 15px;
  display: flex;
  flex-direction: row;
`;

const SLCardLeftRightSplit = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SLCardSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const SLCardName = styled.div`
  font-size: 15px;
  font-weight: bold;
  width: 100%;
  text-align: left;
  height: fit-content;
  margin: auto 0px;
  text-decoration: ${(props) => (props.completed ? "line-through" : "none")};
`;

const SLCardQuantity = styled.div`
  font-size: 15px;
  width: 60px;
  height: fit-content;
  margin: auto 0px;
  margin-right: 5px;
  text-align: right;
`;

const SLCardUnit = styled.div`
  ${"" /* background: blue; */}

  height: fit-content;
  ${
    "" /* margin: auto 0px;
    margin-left: 5px; */
  }
  width: 80px;
  text-align: left;
  padding-left: 15px;
`;

const SLCardCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
  border-radius: 2px;
  margin: auto;
  margin-left: 5px;
  margin-right: 15px;
`;

const TRUNCATE_THRESHOLD = 4;

const ShoppingListCard = ({ bundle, multiplier }) => {
  const isMass = MASS_UNITS_OPTIONS.map((option) => {
    return option.value;
  }).includes(bundle.unit);
  const isVolume = VOLUME_UNITS_OPTIONS.map((option) => {
    return option.value;
  }).includes(bundle.unit);
  const unitOptions = isMass
    ? MASS_UNITS_OPTIONS
    : isVolume
    ? VOLUME_UNITS_OPTIONS
    : COUNT_UNITS_OPTIONS;
  const [completed, setCompleted] = useState(false);
  const [quantity, setQuantity] = useState();
  const [surplus, setSurplus] = useState(false);
  const [unitOption, setUnitOption] = useState(
    unitOptions.filter((option) => {
      return option.value.toLowerCase() === bundle.unit.toLowerCase();
    })[0]
  );
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const instantiateQuantity = async () => {
      const token = await getAccessTokenSilently();
      const response = await getShoppingListItem(
        token,
        bundle?.ingredientID,
        getUnitType(unitOption.value),
        unitOption.value,
        multiplier || 1
      );
      if (response.status === 200) {
        setQuantity(response.data.item.delta_quantity);
        setCompleted(response.data.item.completed);
      } else {
        console.error(response);
      }
    };
    if (bundle) {
      instantiateQuantity();
    }
  }, [getAccessTokenSilently, unitOption, multiplier, bundle]);

  useEffect(() => {}, [quantity]);

  useEffect(() => {
    setSurplus(quantity === 0.0);
  }, [quantity]);

  const onCompletedClick = async () => {
    if (surplus) return;

    const token = await getAccessTokenSilently();
    const response = await getShoppingListItem(
      token,
      bundle?.ingredientID,
      getUnitType(unitOption.value),
      unitOption.value,
      multiplier || 1
    );
    if (response.status !== 200) {
      console.error("Failed to get shopping list item");
      return;
    }

    const updateResponse = await updateShoppingListItemInventory(
      token,
      bundle?.ingredientID,
      getUnitType(unitOption.value),
      unitOption.value,
      response.data.item.inventory_quantity,
      response.data.item.delta_unit,
      !completed
    );
    if (updateResponse.status === 200) {
      setCompleted(!completed);
    } else {
      console.error("Failed to save update shopping list item");
    }
  };

  return (
    <SLCard completed={completed} surplus={surplus} onClick={onCompletedClick}>
      <SLCardCheckbox>
        {(surplus || completed) && <IoCheckmark size="medium" />}
      </SLCardCheckbox>
      <SLCardLeftRightSplit>
        <SLCardSection>
          <SLCardName completed={surplus || completed}>
            {bundle ? bundle.name : "Loading..."}
          </SLCardName>
        </SLCardSection>
        {bundle && (
          <SLCardSection>
            <SLCardQuantity>
              {parseFloat(quantity?.toPrecision(TRUNCATE_THRESHOLD))}
            </SLCardQuantity>
            {unitOptions.length === 1 ? (
              <SLCardUnit>{unitOption.text}</SLCardUnit>
            ) : (
              <Dropdown
                style={{
                  marginLeft: "5px",
                  minWidth: "80px",
                  maxWidth: "80px",
                }}
                selection
                options={unitOptions}
                onChange={(e, v) => {
                  setUnitOption(
                    unitOptions.filter((option) => {
                      return (
                        option.value.toLowerCase() === v.value.toLowerCase()
                      );
                    })[0]
                  );
                }}
                text={unitOption.text}
              />
            )}
          </SLCardSection>
        )}
      </SLCardLeftRightSplit>
    </SLCard>
  );
};

export default ShoppingListCard;
