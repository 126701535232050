import React from "react";
import NotificationModal from "./NotificationModal";
import { useNotificationContext } from "../../contexts/NotificationContext";
import "./NotificationCenter.css";

const NotificationCenter = () => {
  const { notifications } = useNotificationContext();

  return (
    <div className="notification-center-container">
      {notifications.map((notification) => (
        <NotificationModal notification={notification} duration={10000} />
      ))}
    </div>
  );
};

export default NotificationCenter;
