import React from "react";
import WizardBundleManager2 from "../../../wizard/WizardBundleManager2";
import WizardCard from "../../../wizard/WizardCard";

const MealPrefIngredientsCard = ({
  back,
  forward,
  isFirst,
  wizData,
  setWizData,
}) => {
  const shouldSkipBundleCard = () => {
    return wizData?.bundles?.length === 0;
  };

  return (
    <WizardCard
      title="Add Ingredients"
      setData={setWizData}
      shouldSkip={shouldSkipBundleCard()}
      back={back}
      forward={forward}
      isFirst={isFirst}
    >
      <div
        style={{
          fontStyle: "italic",
          textAlign: "center",
          margin: "20px",
          fontSize: "14px",
        }}
      >
        <p>Add ingredients for {wizData.suggested_servings} servings.</p>
      </div>
      <WizardBundleManager2 data={wizData} setData={setWizData} />
    </WizardCard>
  );
};

export default MealPrefIngredientsCard;
