import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import { Divider, Dropdown } from "semantic-ui-react";
import {
  MASS_UNITS_OPTIONS,
  VOLUME_UNITS_OPTIONS,
  COUNT_UNITS_OPTIONS,
  SUPPORTED_UNITS_OPTIONS,
  getUnitType,
} from "../../general/Constant";
import {
  getShoppingListItem,
  updateShoppingListItemInventory,
} from "../../../api/OtterApi";
import CollapsibleCard from "../../../components/CollapsibleCard";

const SLCardColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
`;

const InvCardUnit = styled.div`
  ${"" /* background: blue; */}

  height: fit-content;
  ${
    "" /* margin: auto 0px;
    margin-left: 5px; */
  }
  width: 80px;
  text-align: left;
  padding-left: 15px;
`;

const InvCardQuantity = styled.div`
  ${"" /* background: brown; */}
  font-size: 15px;
  width: 60px;
  height: fit-content;
  ${
    "" /* margin: auto 0px;
    margin-right: 5px; */
  }
  margin: auto 0px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  ${"" /* padding-right: 15px; */}
  text-align: right;
`;

const InvCardLabel = styled.div`
  color: ${(props) => props.color};
  ${(props) => props.bold && "font-weight: bold"};
  height: fit-content;
  width: 40px;
  margin: auto 0px;
  margin-right: 15px;
  text-align: left;
`;

const InvCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
  width: 70%;
  ${"" /* background: red; */}
  margin: auto;
`;

const InventoryCard = ({ bundle, multiplier }) => {
  const isMass = MASS_UNITS_OPTIONS.map((option) => {
    return option.value;
  }).includes(bundle.unit);
  const isVolume = VOLUME_UNITS_OPTIONS.map((option) => {
    return option.value;
  }).includes(bundle.unit);
  const unitOptions = isMass
    ? MASS_UNITS_OPTIONS
    : isVolume
    ? VOLUME_UNITS_OPTIONS
    : COUNT_UNITS_OPTIONS;
  const [shoppingListItem, setShoppingListItem] = useState();
  const [newInventoryQuantity, setNewInventoryQuantity] = useState();
  const [newInventoryUnit, setNewInventoryUnit] = useState(bundle.unit);
  const [newInventoryCompleted, setNewInventoryCompleted] = useState();
  const [totalUnit, setTotalUnit] = useState(bundle.unit);
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {}, [multiplier]);

  useEffect(() => {
    const instantiateShoppingListItem = async () => {
      const token = await getAccessTokenSilently();
      const response = await getShoppingListItem(
        token,
        bundle?.ingredientID,
        getUnitType(bundle.unit),
        totalUnit,
        multiplier || 1
      );
      if (response.status === 200) {
        setShoppingListItem(response.data.item);
      } else {
        console.error(response);
      }
    };
    if (bundle) {
      instantiateShoppingListItem();
    }
  }, [bundle, getAccessTokenSilently, totalUnit, multiplier]);

  useEffect(() => {
    if (shoppingListItem) {
      setNewInventoryQuantity(shoppingListItem.inventory_quantity);
      setNewInventoryUnit(shoppingListItem.inventory_unit);
      setNewInventoryCompleted(shoppingListItem.completed);
      if (!totalUnit) {
        setTotalUnit(shoppingListItem.inventory_unit);
      }
    }
  }, [shoppingListItem]);

  useEffect(() => {
    const updateInventory = async () => {
      const token = await getAccessTokenSilently();
      const updateResponse = await updateShoppingListItemInventory(
        token,
        bundle?.ingredientID,
        getUnitType(newInventoryUnit),
        newInventoryUnit,
        parseFloat(Math.max(newInventoryQuantity, 0)),
        totalUnit,
        newInventoryCompleted
      );
      if (updateResponse.status === 200) {
        // TODO: update the shopping list item update endpoint to take into consideration a person multiplier
        // similarly to the get endpoint so a second api call is not necessary

        const getResponse = await getShoppingListItem(
          token,
          bundle?.ingredientID,
          getUnitType(bundle.unit),
          totalUnit,
          multiplier || 1
        );
        if (getResponse.status === 200) {
          setShoppingListItem(getResponse.data.item);
        } else {
          console.error(getResponse);
        }
      } else {
        console.error(updateResponse);
      }
    };
    if (newInventoryQuantity >= 0 && newInventoryQuantity !== "") {
      if (
        newInventoryQuantity !== shoppingListItem.inventory_quantity ||
        newInventoryUnit !== shoppingListItem.inventory_unit
      ) {
        updateInventory();
      }
    }
  }, [
    bundle.ingredientID,
    getAccessTokenSilently,
    newInventoryQuantity,
    newInventoryUnit,
  ]);

  const quantityUnsaved = () => {
    return shoppingListItem?.inventory_quantity !== newInventoryQuantity;
  };

  const unitUnsaved = () => {
    return shoppingListItem?.inventory_unit !== newInventoryUnit;
  };

  const unitValueToText = (value) => {
    return SUPPORTED_UNITS_OPTIONS.filter((option) => option.value === value)[0]
      .text;
  };

  return (
    <CollapsibleCard
      title={bundle ? bundle.name : "Loading..."}
      border
      borderColor={"lightgrey"}
    >
      <SLCardColumn>
        <InvCardRow style={{ marginBottom: "5px" }}>
          <InvCardLabel color={"grey"}>Need</InvCardLabel>
          <InvCardQuantity>
            {parseFloat(shoppingListItem?.quantity?.toPrecision(3))}
          </InvCardQuantity>
          <InvCardUnit>
            {shoppingListItem && unitValueToText(shoppingListItem.unit)}
          </InvCardUnit>
        </InvCardRow>
        <InvCardRow>
          <InvCardLabel>Have</InvCardLabel>
          <input
            style={{
              fontSize: "15px",
              width: "40px",
              height: "fit-content",
              margin: "auto 0px",
              marginLeft: "20px",
              textAlign: "right",
            }}
            value={parseFloat(
              newInventoryQuantity &&
                parseFloat(newInventoryQuantity).toPrecision(3)
            )}
            onChange={(e) => {
              setNewInventoryQuantity(e.target.value);
            }}
            type="number"
          />
          {unitOptions.length === 1 ? (
            <InvCardUnit>
              {newInventoryUnit && unitValueToText(newInventoryUnit)}
            </InvCardUnit>
          ) : (
            <Dropdown
              style={{
                minWidth: "80px",
                maxWidth: "80px",
              }}
              selection
              options={unitOptions}
              onChange={(e, v) => {
                setNewInventoryUnit(v.value);
              }}
              text={newInventoryUnit && unitValueToText(newInventoryUnit)}
            />
          )}
        </InvCardRow>
        <Divider />
        <InvCardRow>
          <InvCardLabel bold>Buy</InvCardLabel>
          <InvCardQuantity>
            {quantityUnsaved() || unitUnsaved()
              ? "-"
              : parseFloat(shoppingListItem?.delta_quantity?.toPrecision(3))}
          </InvCardQuantity>
          {unitOptions.length === 1 ? (
            <InvCardUnit>{totalUnit && unitValueToText(totalUnit)}</InvCardUnit>
          ) : (
            <Dropdown
              style={{
                minWidth: "80px",
                maxWidth: "80px",
              }}
              selection
              options={unitOptions}
              onChange={(e, v) => {
                setTotalUnit(v.value);
              }}
              text={totalUnit && unitValueToText(totalUnit)}
            />
          )}
        </InvCardRow>
      </SLCardColumn>
    </CollapsibleCard>
  );
};

export default InventoryCard;
