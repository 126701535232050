import React, { useState, useEffect } from "react";

const DaySelector = ({ onDaysToggled, initial }) => {
    const [toggleStates, setToggleStates] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ]);

    const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    const onDayClick = (index) => {
        let tempToggles = [...toggleStates];
        tempToggles[index] = !tempToggles[index];
        setToggleStates(tempToggles);
    };

    useEffect(() => {
        // Map toggled booleans to a list of actual days
        const toggledDays = toggleStates
            .map((toggle, index) => {
                if (!toggle) {
                    return null;
                } else {
                    return days[index];
                }
            })
            .filter((day) => day !== null);
        onDaysToggled(toggledDays);
    }, [toggleStates]);

    const getToggleStyle = (toggle) => {
        if (initial) {
            const baseStyle = {
                borderStyle: "solid",
                borderRadius: "25px",
                borderWidth: "1px",
                width: "30px",
                height: "30px",
                textAlign: "center",
                paddingTop: "5px",
                marginRight: "5px",
            };

            if (toggle) {
                return {
                    ...baseStyle,
                    backgroundColor: "grey",
                    color: "white",
                };
            } else {
                return {
                    ...baseStyle,
                    backgroundColor: "white",
                    color: "grey",
                };
            }
        } else {
            if (toggle) {
                return {
                    backgroundColor: "grey",
                    color: "white",
                    display: "in-line",
                    border: "solid",
                    width: "fit-content",
                };
            } else {
                return {
                    backgroundColor: "white",
                    color: "grey",
                    display: "in-line",
                    width: "fit-content",
                };
            }
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexWrap: "nowrap",
                flexDirection: "row",
                justifyContent: "space-between",
            }}
        >
            {days.map((name, index) => (
                <div
                    key={index}
                    style={getToggleStyle(toggleStates[index])}
                    onClick={() => onDayClick(index)}
                >
                    {initial ? name.charAt(0) : name}
                </div>
            ))}
        </div>
    );
};

export default DaySelector;
