import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getIngredient } from "../../../../api/OtterApi";
import SearchableDropdown from "../../../../components/SearchableDropdown";
import UpcScanner from "../../../product/UpcScanner";

import { searchProducts } from "../../../../api/OtterApi";
import { PiBarcode } from "react-icons/pi";
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
} from "semantic-ui-react";

function exampleReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

const ProductMapperItem = ({
  ingredientId,
  productMapping,
  setProductMapping,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [ingredient, setIngredient] = useState();
  const [products, setProducts] = useState();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dropdownSelected, setDropdownSelected] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [productId, setProductId] = useState();

  useEffect(() => {
    if (productMapping) {
      console.log(productMapping);
    }
  }, [productMapping]);

  const updateMapping = (newProductId) => {
    console.log(productMapping);
    let productMappingCopy = [...productMapping];

    let found = false;
    for (let i = 0; i < productMappingCopy?.length; i++) {
      if (productMappingCopy[i].ingredient_id === ingredientId) {
        productMappingCopy[i].product_id = newProductId;
        found = true;
        break;
      }
    }

    if (!found) {
      productMappingCopy = [
        ...productMappingCopy,
        { ingredient_id: ingredientId, product_id: productId },
      ];
    }

    setProductMapping(productMappingCopy);
  };
  useEffect(() => {
    if (productId) {
      updateMapping(productId);
      // const productMappingCopy = [...productMapping];

      // let found = false;
      // for (let i = 0; i < productMappingCopy?.length; i++) {
      //   if (productMappingCopy[i].ingredient_id === ingredientId) {
      //     productMappingCopy[i].product_id = productId;
      //     found = true;
      //     break;
      //   }
      // }

      // if (!found) {
      //   productMappingCopy = [
      //     ...productMappingCopy,
      //     { ingredient_id: ingredientId, product_id: productId },
      //   ];
      // }

      // setProductMapping(productMappingCopy);
      dispatch({ type: "CLOSE_MODAL" });
      console.log("Closing modal");
    }
  }, [productId]);

  // useEffect(() => {
  //   const initiateMeals = async () => {
  //     const token = await getAccessTokenSilently();
  //     const getProductsResponse = await listProducts(token, 30, 0, searchQuery);

  //     if (getProductsResponse.status === 200) {
  //       setProducts(getProductsResponse.data.products);
  //     }
  //   };

  //   initiateMeals();
  // }, [searchQuery]);

  useEffect(() => {
    const instantiateIngredient = async () => {
      const token = await getAccessTokenSilently();
      const response = await getIngredient(token, ingredientId);
      if (response.status === 200) {
        setIngredient(response.data.ingredient);
      } else {
        console.error(response.data.error);
      }
    };
    instantiateIngredient();
  }, [getAccessTokenSilently, ingredientId]);

  useEffect(() => {
    const instantiateProducts = async () => {
      const token = await getAccessTokenSilently();
      const response = await searchProducts(token, 10, 0, searchQuery);
      if (response.status === 200) {
        setProducts(response.data.products);
      } else {
        console.error(response.data.error);
      }
    };
    instantiateProducts();
  }, [getAccessTokenSilently, ingredientId, searchQuery]);

  useEffect(() => {
    if (products) {
      const initialProduct = productMapping[ingredientId];
      if (initialProduct) {
        setDropdownSelected({
          value: initialProduct,
          label: initialProduct.name,
        });
      }
    }
  }, [ingredientId, productMapping, products]);

  useEffect(() => {
    if (dropdownSelected?.label === "None") {
      const productMappingCopy = { ...productMapping };
      productMappingCopy[ingredientId] = null;
      setProductMapping(productMappingCopy);
    } else if (
      dropdownSelected?.value &&
      productMapping[ingredientId] !== dropdownSelected?.value
    ) {
      updateMapping(dropdownSelected.value);

      // const productMappingCopy = { ...productMapping };
      // productMappingCopy[dropdownSelected.value.ingredient_id] =
      //   dropdownSelected.value;
      // setProductMapping(productMappingCopy);
    }
  }, [dropdownSelected]);

  useEffect(() => {
    if (products && products.length > 0) {
      let options = products.map((product) => {
        const option = {
          value: product,
          label: product.name,
        };
        return option;
      });
      options = [...options, { value: null, label: "None" }];
      setDropdownOptions(options);
    }
  }, [products]);

  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined,
  });
  const { open, dimmer } = state;

  return (
    <div style={{ height: "100%" }}>
      <Modal
        style={{}}
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
      >
        <ModalHeader>Scan Product</ModalHeader>
        <ModalContent>
          Scan the product's barcode which you would like to use for the
          following ingredient: {ingredient?.name}
          <UpcScanner setProductId={setProductId} />
        </ModalContent>
        <ModalActions>
          <Button negative onClick={() => dispatch({ type: "CLOSE_MODAL" })}>
            Exit
          </Button>
        </ModalActions>
      </Modal>
      <div style={{ display: "flex" }}>
        <div>
          <h2>{ingredient?.name}</h2>
        </div>
        <div
          style={{
            marginLeft: "10px",
            marginTop: "5px",
            color: "blue",
          }}
        >
          <PiBarcode
            size={"2em"}
            onClick={() => dispatch({ type: "OPEN_MODAL" })}
          />
        </div>
      </div>
      {productId}
      {!productId && (
        <SearchableDropdown
          options={dropdownOptions}
          selected={dropdownSelected}
          onSelectedChange={setDropdownSelected}
          setSearchQuery={setSearchQuery}
          // label={ingredient?.name}
        />
      )}
    </div>
  );
};

export default ProductMapperItem;
