import React from "react";

const WizardNote = ({ text }) => {
    return (
        <div
            style={{
                width: "100%",
                margin: "auto",
                padding: "15px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "5px",
                borderColor: "lightgrey",
                color: "grey",
            }}
        >
            {text}
        </div>
    );
};

export default WizardNote;
