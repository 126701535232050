import React, { useState, useEffect } from "react";
import "./EditLabel.css";

const EditLabel = ({ value, placeholder, onChange, isEditable = true }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [text, setText] = useState(value);
    const [editText, setEditText] = useState(value);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        setText(value);
        setEditText(value);
    }, [value]);

    const cancelEdit = () => {
        setIsEdit(false);
        setEditText(text);
    };

    const validateString = (text) => {
        if (!text) {
            return "String cannot be empty.";
        }
    };

    const saveEdit = () => {
        const error = validateString(editText);
        if (!error) {
            setIsEdit(false);
            if (editText === text) {
                return;
            }

            setText(editText);
            onChange(editText);
        } else {
            setErrorMessage(error);
        }
    };

    const editMode = () => {
        return (
            <React.Fragment>
                <textarea
                    className="edit-label-input"
                    value={editText || ""}
                    onChange={(e) => setEditText(e.target.value)}
                    placeholder={placeholder}
                />
                <br />
                <span role="img" aria-label="save" onClick={() => saveEdit()}>
                    ✅
                </span>
                <span
                    role="img"
                    aria-label="cancel"
                    onClick={() => cancelEdit()}
                >
                    ❌
                </span>
                <div className="errorMessage">{errorMessage}</div>
            </React.Fragment>
        );
    };

    const viewMode = () => {
        return (
            <div
                className="edit-label-view"
                onClick={() => {
                    if (isEditable) {
                        setIsEdit(true);
                    }
                }}
            >
                {text}
            </div>
        );
    };

    return isEdit ? editMode() : viewMode();
};

export default EditLabel;
