import React from "react";

const WizardTextView = ({ title, value }) => {
    const inputStyle = {
        maxWidth: "300px",
        display: "flex",
        // justifyContent: "space-between",
        // backgroundColor: "lightblue",
        margin: "auto",
        marginTop: "10px",
    };
    return (
        <div style={inputStyle}>
            <div
                style={{
                    minWidth: "80px",
                    margin: "auto",
                    marginRight: "0px",
                    textAlign: "right",
                    // backgroundColor: "pink",
                }}
            >
                <span>{title}: </span>
            </div>
            <div
                style={{
                    // backgroundColor: "red",

                    minWidth: "70px",
                    margin: "auto",
                    marginLeft: "20px",
                    width: "fit-content",
                }}
            >
                {value}
            </div>
        </div>
    );
};

export default WizardTextView;
