import React from "react";
import WizardBundlePref from "./WizardBundlePref";
import WizardBundleTitle from "./WizardBundleTitle";

const WizardBundlePrefList = ({ bundlePrefs, removeBundle }) => {
    return (
        <>
            <div style={{ textAlign: "center" }}>
                {bundlePrefs?.length > 0 ? (
                    <>
                        <WizardBundleTitle />
                        {bundlePrefs.map((bundlePref, index) => {
                            return (
                                <WizardBundlePref
                                    bundlePref={bundlePref}
                                    index={index}
                                    removeBundle={removeBundle}
                                />
                            );
                        })}
                    </>
                ) : (
                    <span style={{ color: "grey" }}>
                        No ingredients have been added yet.
                    </span>
                )}
            </div>
        </>
    );
};

export default WizardBundlePrefList;
