import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { createUser } from "../../api/OtterApi";
import { useHistory } from "react-router-dom";

const SignUpCard = styled.div`
    border-style: solid;
    border-color: lightgrey;
    border-width: 1px;
    margin: auto;
    margin-top: 100px;
    width: 500px;
    padding: 50px;
`;

const SignUpHeader = styled.h1`
    padding-bottom: 1rem;
`;

// https://stackoverflow.com/questions/17097512/how-can-i-change-the-border-outline-color-for-input-and-textarea-elements-in-twi
const FieldBubble = styled.input`
    border-style: solid;
    border-color: ${props => props.isInvalid ? "pink" : "lightgrey"};
    border-width: 2px;
    border-radius: 20px;
    padding: 0.5rem;
    padding-left: 15px;
    display: block;
    width: 390px;
    ::placeholder {
        color: #BEBEBE;
    }
    :focus {
        border-color: ${props => props.isInvalid ? "red" : "grey"};
        outline: 0 none;
    }
    margin-top: 2.25rem;
`;

const CreateAccountButton = styled.div`
    margin: auto;
    font-weight: bold;
    color: white;
    background-color: ${props => props.canCreateUser ? "rgb(115, 147, 179)" : "lightgrey"};
    width: 150px;
    padding: 0.5rem;
    border-radius: 20px;
    width: 390px;
    margin-top: 3rem;
`;

const InvalidInputMessage = styled.div`
    padding-top: 0.25rem;
    font-size: small;
    color: red;
    margin-bottom: 1rem;
    position: absolute;
`;

const DialogMessage = styled.div`
    padding-top: 1rem;
    color: ${props => props.createUserError ? "red" : "black"};
    margin-bottom: 1rem;
`;

const UserSignUpPage = () => {

    const { getAccessTokenSilently } = useAuth0();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [invalidEmailMessage, setInvalidEmailMessage] = useState("");
    const [invalidFirstNameMessage, setInvalidFirstNameMessage] = useState("");
    const [invalidLastNameMessage, setInvalidLastNameMessage] = useState("");
    const [message, setMessage] = useState("");
    const [createUserError, setCreateUserError] = useState(false);
    const [canCreateUser, setCanCreateUser] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const history = useHistory();

    useEffect(() => {
        setCanCreateUser(firstName && lastName && email && !invalidEmailMessage);
    }, [firstName, lastName, email]);

    const processInput = (newInput, inputName, setInvalidMessage, setInput, limit = 30) => {
        if (newInput && newInput.length > limit) {
            setInvalid(false);
            return;
        }

        if (newInput) {
            setInvalidMessage("");
            setInvalid(false);
        } else {
            setInvalidMessage(`${inputName} cannot be empty.`);
            setInvalid(true);
        }

        setInput(newInput);
    }

    // https://www.w3resource.com/javascript/form/email-validation.php
    const validateEmail = (inputText) => {
        const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (inputText.match(mailFormat)) {
            console.log("Valid email found");
        } else {
            console.log("Invalid email found");
            setInvalidEmailMessage("Please enter a valid email address.");
            setInvalid(true);
        }
    }

    const processFirstName = (newFirstName) => {
        processInput(newFirstName, "First name", setInvalidFirstNameMessage, setFirstName);
    }

    const processLastName = (newLastName) => {
        processInput(newLastName, "Last name", setInvalidLastNameMessage, setLastName);
    }

    const processEmail = (newEmail) => {
        processInput(newEmail, "Email", setInvalidEmailMessage, setEmail, 50);
        validateEmail(newEmail);
    }

    const onCreateAccountClick = async () => {
        if (!canCreateUser || invalid) return;

        const token = await getAccessTokenSilently();
        const user = {
            "first_name": firstName,
            "last_name": lastName,
            "email": email
        }

        const response = await createUser(token, user);
        if (response.status === 201) {
            console.log("Successfully created user.");
            setMessage("Your account was created successfully.");
            history.push("/dashboard");
        } else {
            console.error(response.data.error);
            setMessage("Something went wrong while creating your account. Please refresh and try again.")
            setCreateUserError(true);
        }
    };
    
    return (
        <SignUpCard>
            <SignUpHeader>Finish Creating Your Account</SignUpHeader>
            <FieldBubble
                    isInvalid={invalidFirstNameMessage} 
                    label="First Name"
                    type="text"
                    value={firstName}
                    placeholder="First Name"
                    onChange={(e) => {processFirstName(e.target.value)}} />
            <InvalidInputMessage> {invalidFirstNameMessage} </InvalidInputMessage>
            <FieldBubble label="Last Name"
                    isInvalid={invalidLastNameMessage} 
                    type="text"
                    value={lastName}
                    placeholder="Last Name"
                    onChange={(e) => {processLastName(e.target.value)}} />
            <InvalidInputMessage> {invalidLastNameMessage} </InvalidInputMessage>
            <FieldBubble label="Email"
                    isInvalid={invalidEmailMessage} 
                    type="text"
                    value={email}
                    placeholder="Email"
                    onChange={(e) => {processEmail(e.target.value)}} />
            <InvalidInputMessage> {invalidEmailMessage} </InvalidInputMessage>
            <CreateAccountButton canCreateUser={canCreateUser}
                    onClick={() => {onCreateAccountClick()}} >
                Create Account
            </CreateAccountButton>
            <DialogMessage createUserError={createUserError}> {message} </DialogMessage>
        </SignUpCard>
    );
};

export default UserSignUpPage;
