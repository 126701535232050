import React from "react";
import "./SearchBar.css";
import * as BsIcons from "react-icons/bs";

const SearchBar = ({ searchText, setSearchText }) => {
    const handleChange = (e) => {
        setSearchText(e.target.value);
    };

    return (
        <div className="search-bar-container">
            <div className="search-bar-text-field">
                <BsIcons.BsSearch className="search-bar-magnifying-glass" />
                <input
                    className="search-bar-input"
                    placeholder="Search"
                    type="text"
                    value={searchText}
                    onChange={handleChange}
                />
            </div>
            {/* <div className="search-bar-create-button"></div> */}
        </div>
    );
};

export default SearchBar;
