import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import { createProductFromUpc } from "../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";

const formatUpc = (upc) => {
    if (upc?.length === 12) {
        return upc;
    } else if (upc?.length === 13) {
        // console.log(`Shortinging ${upc} to ${upc.substring(1)}`);
        return upc.substring(1);
    }

    console.error(`Could not format upc: ${upc}`);
    return null;
};

const UpcScanner = ({ setProduct, redirect = false }) => {
    const { getAccessTokenSilently } = useAuth0();
    const history = useHistory();
    const [barcodeDetector, setBarcodeDetector] = useState();
    const [barcodeBitmap, setBarcodeBitmap] = useState();
    const [image, setImage] = useState();
    const [message, setMessage] = useState();
    const [upc, setUpc] = useState();

    const [polling, setPolling] = useState(true);

    const webcamRef = React.useRef(null);
    const capture = React.useCallback(() => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        // console.log("Setting imageSrc");
        setImage(imageSrc);
    }, [webcamRef]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (polling) {
                // console.log("Polling...");

                // console.log("Taking screenshot");
                capture();
            }
        }, 500);

        // Cleanup function to stop polling
        return () => {
            clearInterval(intervalId);
            // console.log("Polling stopped");
        };
    }, [polling]); // Dependency array: polling will restart if this value changes

    // Example function to stop polling
    // const stopPolling = () => {
    //   setPolling(false);
    // };

    const createProductResource = async (token, upcPayload) => {
        const createProductResponse = await createProductFromUpc(
            token,
            upcPayload
        );

        if (
            createProductResponse.status === 201 ||
            createProductResponse.status === 200
        ) {
            const product = createProductResponse.data.product;

            if (setProduct) {
                // console.log("Setting product ID: " + product?.id);
                setProduct(product);
            }

            if (redirect) {
                history.push("/product/" + product?.productId);
            }
        } else {
            setMessage(createProductResponse.data.error);
        }
    };

    const saveProduct = async () => {
        // if (saving) return;
        // setSaving(true);
        const token = await getAccessTokenSilently();
        // console.log("Saving product");
        createProductResource(token, { upc });

        // setSaving(false);
    };

    useEffect(() => {
        if (upc) {
            saveProduct();
        }
    }, [upc]);

    useEffect(() => {
        if (typeof globalThis === "undefined") {
            if (typeof window !== "undefined") {
                window.globalThis = window;
            } else if (typeof global !== "undefined") {
                global.globalThis = global;
            }
        }

        // check compatibility
        if (!("BarcodeDetector" in window.globalThis)) {
            console.log("Barcode Detector is not supported by this browser.");
            setBarcodeDetector(
                new window.BarcodeDetector({
                    formats: ["code_39", "codabar", "ean_13"],
                })
            );
        } else {
            console.log("Barcode Detector supported!");

            setBarcodeDetector(
                new window.BarcodeDetector({
                    formats: ["code_39", "codabar", "ean_13"],
                })
            );
            console.log("Finished creating barcode detector.");
        }
    }, []);

    useEffect(() => {
        async function createImageBitmapFromSource() {
            try {
                // Create an HTMLImageElement
                const img = new Image();
                img.src = image;

                // Wait for the image to load
                await new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });

                // Create an ImageBitmap from the HTMLImageElement
                const imageBitmap = await createImageBitmap(img);
                setBarcodeBitmap(imageBitmap);
                // console.log("Finished setting bitmap.");
                setMessage("Finished setting bitmap.");
                // Use the imageBitmap object as needed
            } catch (error) {
                console.error("Error creating ImageBitmap:", error);
                setMessage("Failed to create bitmap.");
            }
        }

        if (image) {
            // console.log("Creating bitmap...");
            setMessage("Creating bitmap...");
            createImageBitmapFromSource();
        }
    }, [image]);

    useEffect(() => {
        if (barcodeBitmap) {
            // console.log("Scanning barcodes...");
            setMessage("Scanning barcodes...");
            barcodeDetector
                .detect(barcodeBitmap)
                .then((barcodes) => {
                    if (barcodes?.length === 0) {
                        setMessage("No barcode found");
                    } else {
                        // console.log(barcodes);
                        barcodes.forEach((barcode) => {
                            // console.log(barcode.rawValue);
                            setUpc(formatUpc(barcode.rawValue));
                            setMessage(formatUpc(barcode.rawValue));
                            setPolling(false);
                        });
                    }
                    // console.log(barcodes);
                })
                .catch((err) => {
                    // console.log(err);
                    setMessage("Failed to scan barcodes.");
                });
        }
    }, [barcodeBitmap]);

    const videoConstraints = {
        // width: 1280,
        // height: 720,
        facingMode: "user",
    };

    return (
        // <div style={{ width: "300px", height: "300px" }}>
        <div>
            {/* {message} */}
            <div style={{ display: "flex" }}>
                <Webcam
                    ref={webcamRef}
                    audio={false}
                    // height={720}
                    screenshotFormat="image/png"
                    // width={1280}
                    videoConstraints={videoConstraints}
                >
                    {/* {({ getScreenshot }) => (
            <button
              onClick={() => {
                setPolling(!polling);
              }}
            >
              Capture photo
            </button>
          )} */}
                </Webcam>
                {/* {image && <img src={image} />} */}
            </div>
        </div>
    );
};

export default UpcScanner;
