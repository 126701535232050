import React from "react";
import { useHistory } from "react-router-dom";

const ModifierButtons = ({ onDelete, isDeleting, resourceId }) => {
    const history = useHistory();

    const onEdit = () => {
        history.push("edit/" + resourceId);
    };

    return (
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <button class="ui secondary button" onClick={() => onEdit()}>
                Edit
            </button>
            <button
                class="ui negative button"
                onClick={onDelete}
                isLoading={isDeleting}
            >
                Delete
            </button>
        </div>
    );
};

export default ModifierButtons;
