import React, { useEffect, useState } from "react";
import { getIngredient } from "../../api/OtterApi";

import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { unitValueToText } from "../../pages/general/Constant";
import { smartRound } from "../../utils/general";

const WizardBundlePref = ({ bundlePref, index, removeBundle }) => {
    const [ingredient, setIngredient] = useState();
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const instantiateIngredient = async () => {
            const token = await getAccessTokenSilently();
            const response = await getIngredient(
                token,
                bundlePref.ingredient_id
            );
            if (response.status === 200) {
                setIngredient(response.data.ingredient);
            } else {
                console.error(response);
            }
        };
        instantiateIngredient();
    }, []);

    return (
        <div
            class="ui image label"
            style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px auto",
                // backgroundColor: "red",
            }}
        >
            <div
                style={{
                    // backgroundColor: "red",

                    width: "100%",
                    // borderStyle: "solid",
                    // borderWidth: "1px",
                    // borderRadius: "3px",
                    // margin: "10px auto",
                    // marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-around",
                    flexDirection: "row",
                }}
            >
                <div
                    style={{
                        width: "100px",
                        textAlign: "center",
                        margin: "auto",
                    }}
                >
                    {smartRound(bundlePref.quantity)}
                </div>
                <div
                    style={{
                        width: "100px",
                        textAlign: "center",
                        margin: "auto",
                    }}
                >
                    {unitValueToText(bundlePref.units)}
                </div>
                <div
                    style={{
                        width: "100px",
                        textAlign: "center",
                        margin: "auto",
                    }}
                >
                    <Link to={`/ingredient/${bundlePref.ingredient_id}`}>
                        {ingredient?.name}
                    </Link>
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        // padding: "5px",
                        flexGrow: 1,
                        width: "170px",
                        textAlign: "left",
                        margin: "auto",
                        paddingLeft: "40px",
                    }}
                >
                    {bundlePref?.alternatives?.map((ingredient, index) => {
                        return (
                            <Link to={`/ingredient/${ingredient.id}`}>
                                {ingredient.name}
                                {index === bundlePref.alternatives.length - 1
                                    ? ""
                                    : ","}
                            </Link>
                        );
                    })}
                </div>
                {removeBundle ? (
                    <div style={{ width: "30px", margin: "auto" }}>
                        <Icon
                            name="delete"
                            size="large"
                            onClick={() => {
                                removeBundle(index);
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default WizardBundlePref;
