import React, { useState, useEffect } from "react";
import "./MealCard.css";
import {
  getMeal,
  getMealPreference,
  getMealPreferenceNutrition,
} from "../../../api/OtterApi";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import apple from "../../../resources/2.jpeg";
import * as FiIcons from "react-icons/fi";
import NutritionSquare from "./NutritionSquare";

const getTimePeriod = (time) => {
  if (!time) return;
  const timeParts = time.split(":");
  const rawHour = timeParts[0];
  const rawMinute = timeParts[1];
  const hourAsInt = parseInt(rawHour);
  if (hourAsInt === 12) {
    return `${hourAsInt}:${rawMinute} PM`;
  } else if (hourAsInt === 24) {
    return `${hourAsInt - 12}:${rawMinute} PM`;
  } else if (hourAsInt > 12 && hourAsInt !== 12) {
    return `${hourAsInt - 12}:${rawMinute} PM`;
  } else if (hourAsInt === 0) {
    return `12:${rawMinute} AM`;
  } else {
    return `${hourAsInt}:${rawMinute} AM`;
  }
};

const MealCard = ({
  plannedMealPref,
  onDeletePlannedMealPref,
  onEditPlannedMealPref,
  isEditable,
  showNutrition,
  products,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [meal, setMeal] = useState();
  const [nutrition, setNutrition] = useState();
  const history = useHistory();
  const truncateLength = 30;
  const [pendingDeletion, setPendingDeletion] = useState(false);
  const [mealPreference, setMealPreference] = useState();

  useEffect(() => {
    const initializeMeal = async () => {
      const token = await getAccessTokenSilently();

      const getMealPrefResponse = await getMealPreference(
        token,
        plannedMealPref.mealpreference_id
      );
      if (getMealPrefResponse.status === 200) {
        const mealPref = getMealPrefResponse.data.mealpreference;
        setMealPreference(mealPref);
        const getMealResponse = await getMeal(token, mealPref.meal_id);
        if (getMealResponse.status === 200) {
          const retrievedMeal = getMealResponse.data.meal;
          setMeal(retrievedMeal);
        }

        // const getMealPreferenceNutritionResponse =
        //     await getMealPreferenceNutrition(
        //         token,
        //         plannedMealPref.mealpreference_id,
        //         products
        //     );
        // if (getMealPreferenceNutritionResponse.status === 200) {
        //     setNutrition(
        //         getMealPreferenceNutritionResponse.data.nutrition
        //     );
        // } else {
        //     console.error(
        //         "Failed to get mealpreference nutrition with ID: " +
        //             plannedMealPref.mealpreference_id
        //     );
        // }
      }
    };
    if (plannedMealPref) {
      initializeMeal();
    }
  }, [plannedMealPref]);

  const navigateToMealDetails = (id) => {
    history.push(`/meal/${id}`);
  };

  const getCardElements = () => {
    meal.image_url = `https://picsum.photos/50/50?blur=10&random=${
      Math.floor(Math.random() * 1000) + 1
    }`;
    return (
      <div
        style={{
          padding: "0vh 1vh",
          marginTop: "15px",
        }}
      >
        <div className="meal-card-container">
          <img
            src={meal?.image_url || apple}
            alt="Default meal"
            className="plannedmeal-card-image"
          />
          <div className="meal-card-details">
            <div className="plannedmeal-card-top-row">
              <div className="mealplan-detail-meal-time">
                {getTimePeriod(plannedMealPref.time)}
              </div>
              {isEditable ? (
                <div
                  className="plannedmeal-card-edit"
                  onClick={() => {
                    // onEditPlannedMeal(plannedMeal.plannedmeal_id);
                    // TODO: Add this functionality :sweat
                  }}
                >
                  <FiIcons.FiEdit2 />
                </div>
              ) : null}
              {isEditable ? (
                <div
                  className="plannedmeal-card-delete"
                  onClick={() => {
                    setPendingDeletion(true);
                    onDeletePlannedMealPref(
                      plannedMealPref.id,
                      setPendingDeletion
                    );
                  }}
                >
                  <FiIcons.FiX />
                </div>
              ) : null}
            </div>
            <div
              className="mealplan-detail-meal-name"
              onClick={() => navigateToMealDetails(meal?.id)}
            >
              {meal?.name?.substring(0, truncateLength)}
              {meal?.name?.length > truncateLength ? "..." : ""}
            </div>
            <div className="mealplan-detail-meal-preference-name">
              {mealPreference?.name?.substring(0, truncateLength)}
              {mealPreference?.name?.length > truncateLength ? "..." : ""}
            </div>
          </div>
        </div>
        {showNutrition && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <NutritionSquare
              name={"Cals"}
              value={mealPreference?.nutrition?.calories.toFixed(1) || "n/a"}
              color={"200, 200, 200"}
            />
            <NutritionSquare
              name={"Carbs"}
              value={
                mealPreference?.nutrition?.carbohydrates.toFixed(1) || "n/a"
              }
              color={"248, 214, 137"}
            />
            <NutritionSquare
              name={"Fats"}
              value={mealPreference?.nutrition?.fats?.toFixed(1) || "n/a"}
              color={"136, 175, 248"}
            />
            <NutritionSquare
              name={"Prot"}
              value={mealPreference?.nutrition?.proteins.toFixed(1) || "n/a"}
              color={"241, 161, 146"}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mealplan-detail-meal-card">
      {meal
        ? pendingDeletion
          ? "Deleting..."
          : getCardElements()
        : "Loading..."}
    </div>
  );
};

export default MealCard;
