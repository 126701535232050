import React, { useState, useEffect } from "react";
import FillingProgressBar from "./FillingProgressBar";
import { NotificationDuration } from "../../constants";

import "./NotificationModal.css";

import { IoClose } from "react-icons/io5";

/*

  EXAMPLE USAGE:
  --------------------------------------------------------------------------
  import { useNotificationContext } from "./contexts/NotificationContext";

  const { addNotification } = useNotificationContext();

  addNotification({
    message: `Example notification message!`,
    duration: 5000, // This is 10s by default
  });
  --------------------------------------------------------------------------
*/

const NotificationModal = ({ notification }) => {
  const [dismissed, setDismissed] = useState(false);
  const [hidden, setHidden] = useState(false);
  const durationOrDefault =
    notification?.duration || NotificationDuration.DEFAULT;

  useEffect(() => {
    if (notification) {
      const timeoutId = setTimeout(() => {
        setDismissed(true);
      }, durationOrDefault);

      return () => clearTimeout(timeoutId);
    }
  }, []);

  // This allows us to hide the notification, and allow the lower ones to bump up.
  useEffect(() => {
    if (dismissed) {
      const timeoutId = setTimeout(() => {
        setHidden(true);
      }, 300);

      return () => clearTimeout(timeoutId);
    }
  }, [dismissed]);

  if (hidden) {
    return null;
  }

  return (
    <div className={`notification-modal-container ${dismissed ? "hide" : ""}`}>
      <div className="noti-modal-column">
        <div className="noti-content-container">
          <div
            className="noti-modal-close-button-container"
            onClick={() => {
              setDismissed(true);
            }}
          >
            <IoClose color="grey" />
          </div>
          {notification?.message || "No message"}
        </div>
        <div className="progress-bar-container">
          <FillingProgressBar duration={durationOrDefault} />
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
