import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { isTimeInFuture } from "../../../utils/general";
import {
  getCurrentMealPlan,
  getMeal,
  getMealPreference,
  searchMealPlanPreferences,
} from "../../../api/OtterApi";
import CarouselWidget from "./Carousel";

const stopPropagation = (event) => {
  event.stopPropagation();
};

const getTodaysMeals = (mealPlanPreference) => {
  const planned_meals = mealPlanPreference?.plannedmeal_preferences || [];

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const today = new Date();
  let day = weekday[today.getDay()];
  const todaysMeals = planned_meals.filter((planned_meal) => {
    return planned_meal?.day === day;
  });

  return todaysMeals;
};

const getCarouselMeal = (meal) => {
  return (
    <div
      style={{
        width: "100%",
        height: "60%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div>
        {meal?.id && (
          <Link
            to={`/meal/${meal?.id}`}
            onMouseDown={stopPropagation}
            onTouchStart={stopPropagation}
          >
            <h4 style={{ margin: "auto", height: "100%", width: "70%" }}>
              {meal?.name}
            </h4>
          </Link>
        )}
      </div>
      {meal && (
        <span
          style={{
            marginTop: "5px",
            color: "grey",
            textDecoration: isTimeInFuture(meal?.time)
              ? "none"
              : "line-through",
          }}
        >
          @ {meal.time}
        </span>
      )}
    </div>
  );
};

// const getNextMeal = (plannedMeals) => {
//   if (!plannedMeals) return null;

//   const now = new Date();
//   const futurePlannedMeals = plannedMeals.filter((plannedMeal) => {
//     const [hour, minute] = plannedMeal.time.split(":");
//     const mealDate = new Date();
//     mealDate.setHours(hour);
//     mealDate.setMinutes(minute);
//     return now < mealDate; // TODO: May need to be within 10 minutes give or take?
//   });

//   if (futurePlannedMeals?.length) {
//     return futurePlannedMeals[0];
//   }

//   return null;
// };

const WidgetStates = {
  LOADING: 0,
  NO_MEALPLAN: 1,
  ERROR: 3,
  LOADED: 4,
  NO_MORE_MEALS: 5,
};

const compareTime = (a, b) => {
  const aParts = a.time.split(":");
  const bParts = b.time.split(":");
  if (parseInt(aParts[0]) === parseInt(bParts[0])) {
    if (parseInt(aParts[1]) === parseInt(bParts[1])) {
      return 0;
    } else if (parseInt(aParts[1]) < parseInt(bParts[1])) {
      return -1;
    } else {
      return 1;
    }
  } else if (parseInt(aParts[0]) < parseInt(bParts[0])) {
    return -1;
  } else {
    return 1;
  }
};

const NextMealWidget = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [currentMealplanId, setCurrentMealplanId] = useState();
  const [mealPlanPreference, setMealPlanPreference] = useState({});
  const [mealPreferences, setMealPreferences] = useState([]);
  const [meals, setMeals] = useState([]);
  const [sortedMeals, setSortedMeals] = useState([]);
  const [timeMapping, setTimeMapping] = useState();
  const [nextMealIndex, setNextMealIndex] = useState(0);

  const [state, setState] = useState(WidgetStates.LOADING);

  useEffect(() => {
    if (meals) {
      setSortedMeals(meals.sort(compareTime));
    }
  }, [meals]);

  useEffect(() => {
    if (sortedMeals) {
      let i;
      for (i = 0; i < sortedMeals.length; i++) {
        if (isTimeInFuture(sortedMeals[i].time)) {
          break;
        }
      }
      setNextMealIndex(i);
    }
  }, [sortedMeals]);

  useEffect(() => {
    const initializeCurrentMealPlanID = async () => {
      const token = await getAccessTokenSilently();
      const getCurrentMealPlanResponse = await getCurrentMealPlan(token);
      if (getCurrentMealPlanResponse.status === 200) {
        setCurrentMealplanId(getCurrentMealPlanResponse.data.mealplan_id);
      } else {
        console.error(getCurrentMealPlanResponse.data.error);
        setCurrentMealplanId("N/A");
        setState(WidgetStates.NO_MEALPLAN);
      }
    };
    initializeCurrentMealPlanID();
  }, [getAccessTokenSilently]);

  useEffect(() => {
    const initializeMealPlan = async () => {
      const token = await getAccessTokenSilently();
      const response = await searchMealPlanPreferences(
        token,
        10,
        0,
        "",
        currentMealplanId
      );
      if (response.status === 200) {
        const preferences = response.data.mealplanpreferences || [];
        const default_preference = preferences.filter((preference) => {
          return preference.name.toLowerCase() === "default";
        })[0];
        setMealPlanPreference(default_preference);
      } else {
        console.error(response.data.error);
        setState(WidgetStates.NO_MEALPLAN);
      }
    };
    if (currentMealplanId) {
      initializeMealPlan();
    }
  }, [currentMealplanId, getAccessTokenSilently]);

  useEffect(() => {
    const instantiateNextMeal = async () => {
      const token = await getAccessTokenSilently();

      const timeMap = {};
      const plannedMeals = getTodaysMeals(mealPlanPreference);
      const mealPrefPromises = plannedMeals.map((plannedMeal) => {
        timeMap[plannedMeal?.mealpreference_id] = plannedMeal?.time;
        return getMealPreference(token, plannedMeal?.mealpreference_id);
      });
      setTimeMapping(timeMap);

      Promise.all(mealPrefPromises).then((results) => {
        const temp = results
          .map((result) => {
            const mealPref = result.data.mealpreference;
            if (mealPref) {
              mealPref.time = timeMap[mealPref?.id];
            }

            return result.status === 200 ? mealPref : "null";
          })
          .filter((pref) => pref !== null);
        setMealPreferences(temp);
      });

      // const ingredientPromises = wizData.bundle_prefs.map((bundlePref) => {
      //   return getIngredient(token, bundlePref.ingredient_id);
      // });

      // Promise.all(ingredientPromises).then((results) => {
      //   setIngredients(
      //     results
      //       .map((result) => {
      //         return result.status === 200 ? result.data.ingredient : null;
      //       })
      //       .filter((ingredient) => ingredient !== null)
      //   );
      // });

      // todaysMeals.forEach(async (plannedMeal) => {
      //   const response = await getMealPreference(
      //     token,
      //     plannedMeal?.mealpreference_id
      //   );
      //   if (response.status === 200) {
      //     mealPrefs(response.data.mealpreference);
      //     setNextMealPreference(response.data.mealpreference);
      //   } else {
      //     console.error(response.data.error);
      //   }
      // });
    };

    if (mealPlanPreference) {
      instantiateNextMeal();
    }
  }, [mealPlanPreference]);

  useEffect(() => {
    const instantiateMeals = async () => {
      const token = await getAccessTokenSilently();

      // console.log(mealPreferences);
      const mealToPrefMap = {};

      const mealPromises = mealPreferences.map((mealPreference) => {
        mealToPrefMap[mealPreference.meal_id] = mealPreference.id;
        return getMeal(token, mealPreference.meal_id);
      });

      Promise.all(mealPromises).then((results) => {
        setMeals(
          results
            .map((result) => {
              const resultMeal = result.data.meal;
              if (resultMeal) {
                const localPref = mealToPrefMap[resultMeal?.id];
                resultMeal.time = timeMapping[localPref];
              }
              return result.status === 200 ? resultMeal : null;
            })
            .filter((item) => item !== null)
        );
      });

      // const response = await getMeal(token, nextMealPreference.meal_id);
      // if (response.status === 200) {
      //   setNextMeal(response.data.meal);
      //   setState(WidgetStates.LOADED);
      // } else {
      //   console.error(response.data.error);
      //   setState(WidgetStates.NO_MEALPLAN);
      // }
    };
    if (mealPreferences) {
      instantiateMeals();
    }
  }, [mealPreferences]);

  // useEffect(() => {
  //   const instantiateNextMeal = async () => {
  //     const token = await getAccessTokenSilently();
  //     const response = await getMeal(token, nextMealPreference.meal_id);
  //     if (response.status === 200) {
  //       setNextMeal(response.data.meal);
  //       setState(WidgetStates.LOADED);
  //     } else {
  //       console.error(response.data.error);
  //       setState(WidgetStates.NO_MEALPLAN);
  //     }
  //   };

  //   if (nextMealPreference) {
  //     instantiateNextMeal();
  //   }
  // }, [nextMealPreference]);

  // if (state === WidgetStates.LOADING) {
  //   return (
  //     <div
  //       style={{
  //         width: "100%",
  //         height: "100%",
  //         background: "white",
  //         borderRadius: "5px",
  //         boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.3)",
  //         animation: "fadeIn 1s",
  //       }}
  //     >
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "column",
  //           justifyContent: "center",
  //           height: "100%",
  //         }}
  //       >
  //         <Loader active inline="centered" size="tiny" />
  //       </div>
  //     </div>
  //   );
  // } else
  if (state === WidgetStates.NO_MEALPLAN) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "white",
          borderRadius: "5px",
          boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.3)",
          animation: "fadeIn 1s",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div>
            <h4 style={{ margin: "auto", height: "100%", width: "70%" }}>
              No favorite Meal Plan set.
            </h4>
          </div>
        </div>
      </div>
    );
  } else if (state === WidgetStates.NO_MORE_MEALS) {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "white",
          borderRadius: "5px",
          boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.3)",
          animation: "fadeIn 1s",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div>
            <h4 style={{ margin: "auto", height: "100%", width: "70%" }}>
              You've finished your meals for the day.
            </h4>
          </div>
        </div>
      </div>
    );
  }

  const carouselMealComps = sortedMeals.map(getCarouselMeal);
  const widgetTitles = sortedMeals.map((meal, index) => {
    if (index === nextMealIndex) {
      return "Next Meal";
    } else if (meal?.time) {
      if (isTimeInFuture(meal.time)) {
        return "Future Meal";
      } else {
        return "Previous Meal";
      }
    }
  });

  if (nextMealIndex === meals.length) {
    carouselMealComps.push(<div>You're finished with your meals today.</div>);
    widgetTitles.push("Finished Meals");
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "white",
        borderRadius: "5px",
        boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.3)",
        animation: "fadeIn 1s",
      }}
    >
      {/* <div
        style={{
          height: "20%",
        }}
      >
        <h3
          style={{
            textAlign: "left",
            paddingLeft: "10px",
            paddingTop: "8px",
            height: "100%",
            fontSize: "small",
            fontFamily: "Futura,Trebuchet MS,Arial,sans-serif",
            fontWeight: "lighter",
            color: "grey",
          }}
        >
          Next Meal
        </h3>
      </div> */}

      <CarouselWidget
        titles={widgetTitles}
        data={carouselMealComps}
        startIndex={nextMealIndex}
      />
    </div>
  );
};

export default NextMealWidget;
