import React from "react";

const WizardFloatField = ({
    title,
    value,
    placeholder,
    onChange,
    required,
    hasRequirements = (value) => {
        return value > 0;
    },
    step = "0.1"
}) => {
    const inputStyle = {
        maxWidth: "500px",
        display: "flex",
        justifyContent: "space-between",
        margin: "5px 10px",
    };

    return (
        <div className={"ui form"} style={inputStyle}>
            <div
                style={{
                    minWidth: "70px",
                    margin: "auto",
                    marginRight: "0px",
                    textAlign: "right",
                }}
            >
                <label>{title}: </label>
            </div>
            <div
                style={{
                    display: "flex",
                    // backgroundColor: "red",
                    marginTop: "5px",
                    width: "250px",
                    marginLeft: "30px",
                }}
            >
                <input
                    style={{
                        borderStyle: "solid",
                        borderColor:
                            required === true
                                ? hasRequirements(value)
                                    ? "lightgrey"
                                    : "red"
                                : "lightgrey",
                        borderRadius: "3px",
                        borderWidth: "1px",
                    }}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    type="number"
                    step={step}
                />
                <div
                    style={{
                        marginLeft: "5px",
                        color: "red",
                        width: "15px",
                    }}
                >
                    {required ? "*" : null}
                </div>
            </div>
        </div>
    );
};

export default WizardFloatField;
