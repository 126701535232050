import React, { useState } from "react";

const WizardPage = ({ title, children }) => {
    const [step, setStep] = useState(0);
    const isFirst = step <= 0;
    const forward = () => {
        setStep(step + 1);
    };
    const back = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const childrenWithProps = React.Children.map(children, (child) => {
        return React.isValidElement(child)
            ? React.cloneElement(child, { back, forward, isFirst })
            : child;
    });

    return (
        <div
            style={{
                margin: "auto",
                maxWidth: "600px",
                height: "fit-content",
                marginTop: "50px",
            }}
        >
            <h1 style={{ textAlign: "left" }}>{title}</h1>
            <div
                style={{
                    height: "fit-content",
                    width: "100%",
                }}
            >
                {childrenWithProps[step]}
            </div>
        </div>
    );
};

export default WizardPage;
