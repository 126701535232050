import React, { useState, useEffect } from "react";
import { listProducts } from "../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";

import SearchBar from "../../components/searchbar/SearchBar";
import SearchResultGallery from "../../components/searchbar/gallery/SearchResultGallery";
import * as GrIcons from "react-icons/gr";
import "./ProductPage.css";

const ProductPage = () => {
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [products, setProducts] = useState([]);
    const [hasNext, setHasNext] = useState(false);
    const [searchText, setSearchText] = useState("");
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const getProducts = async () => {
            const token = await getAccessTokenSilently();
            const response = await listProducts(
                token,
                limit,
                offset,
                searchText.trim()
            );
            if (response.status === 200) {
                const rawProducts = response.data.products;
                if (rawProducts.length === 0) {
                    setOffset(0);
                } else {
                    setProducts(rawProducts);
                }
            } else {
                console.error(response.data.error);
            }
        };
        getProducts();
    }, [searchText, limit, offset]);

    useEffect(() => {
        const checkHasNext = async () => {
            const token = await getAccessTokenSilently();
            const response = await listProducts(
                token,
                1,
                offset + limit + 1,
                searchText.trim()
            );
            if (response.status === 200) {
                const rawProducts = response.data.products;
                setHasNext(rawProducts && rawProducts.length > 0);
            } else {
                console.error(response.data.error);
            }
        };
        checkHasNext();
    }, [products]);

    const handlePrevClick = () => {
        setOffset(Math.max(0, offset - limit));
    };

    const handleNextClick = () => {
        setOffset(offset + limit);
    };

    return (
        <div className="product-page-container">
            <SearchBar searchText={searchText} setSearchText={setSearchText} />
            <SearchResultGallery
                title={"Products"}
                resource={"product"}
                results={products}
            />
            <div className="product-search-page-controls">
                <div className="page-prev-button" onClick={handlePrevClick}>
                    {offset > 0 ? <GrIcons.GrPrevious /> : ""}
                </div>
                <div className="page-next-button" onClick={handleNextClick}>
                    {hasNext ? <GrIcons.GrNext /> : ""}
                </div>
            </div>
        </div>
    );
};

export default ProductPage;
