import React from "react";
import NutritionField from "./NutritionField";

const NutritionSection = ({ showNutrition, nutrition }) => {
    if (!showNutrition) {
        return null;
    }

    if (!nutrition) {
        return <div class="ui tiny active inline loader"></div>;
    }

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignText: "center",
                    margin: "0px 10px",
                }}
            >
                <NutritionField
                    title={"Calories"}
                    value={nutrition.calories.toFixed(1) || "n/a"}
                    color={"200, 200, 200"}
                />
                <NutritionField
                    title={"Carbs"}
                    value={nutrition.carbohydrates.toFixed(1) || "n/a"}
                    color={"248, 214, 137"}
                />
                <NutritionField
                    title={"Fats"}
                    value={nutrition.fats.toFixed(1) || "n/a"}
                    color={"136, 175, 248"}
                />
                <NutritionField
                    title={"Proteins"}
                    value={nutrition.proteins.toFixed(1) || "n/a"}
                    color={"241, 161, 146"}
                    isLast={true}
                />
            </div>
        </div>
    );
};

export default NutritionSection;
