import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getMeal, deleteMeal, searchMealPreferences } from "../../api/OtterApi";
import ModifierButtons from "../../components/ModifierButtons";
import DetailPage from "../general/DetailPage";
import { Loader } from "semantic-ui-react";

import { useAuth0 } from "@auth0/auth0-react";
import MealDetails from "./MealDetails";

const MealDetailPage = ({ match }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [meal, setMeal] = useState();
  const [mealPreferences, setMealPreferences] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditable, setIsEditable] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const initializeMeal = async () => {
      const token = await getAccessTokenSilently();
      const mealId = match.params.id;

      const response = await getMeal(token, mealId);
      if (response.status === 200) {
        setMeal(response.data.meal);
        setIsEditable(response.data.editable);

        const mealPrefsResponse = await searchMealPreferences(
          token,
          20,
          0,
          "",
          mealId
        );
        if (mealPrefsResponse.status === 200) {
          setMealPreferences(mealPrefsResponse.data.mealpreferences);
        }
      }
      setIsLoading(false);
    };
    initializeMeal();
  }, []);

  const onDelete = async () => {
    const token = await getAccessTokenSilently();
    setIsDeleting(true);
    const response = await deleteMeal(token, match.params.id);
    if (response.status === 200) {
      history.push("/meal");
    } else {
      console.error(response);
    }
    setIsDeleting(false);
  };

  // const onDeleteBundle = async (bundleId) => {
  //     const token = await getAccessTokenSilently();
  //     const response = await deleteBundleFromMeal(token, meal.id, bundleId);
  //     if (response.status === 200) {
  //         setMeal(response.data.meal);
  //     } else {
  //         console.error(response);
  //     }
  // };

  return (
    <div>
      <DetailPage>
        {isLoading ? (
          <Loader active size="medium">
            Loading
          </Loader>
        ) : meal ? (
          <>
            <MealDetails
              meal={meal}
              mealPrefs={mealPreferences}
              setMealPrefs={setMealPreferences}
            />
            {isEditable && (
              <ModifierButtons
                onDelete={onDelete}
                isDeleting={isDeleting}
                resourceId={match.params.id}
              />
            )}
          </>
        ) : (
          <div>
            Oh no! Something went wrong while retrieving this meal. Please try
            reloading the page.
          </div>
        )}
      </DetailPage>
    </div>
    // <div>
    //     <label>Name: </label>
    //     <EditLabel
    //         value={name}
    //         placeholder="Name"
    //         onChange={onNameChange}
    //         isEditable={isEditable}
    //     />
    //     <br />
    //     <label>Description: </label>
    //     <EditLabel
    //         value={description}
    //         placeholder="Description"
    //         onChange={onDescriptionChange}
    //         isEditable={isEditable}
    //     />
    //     <br />
    //     <EditLink
    //         value={link}
    //         placeholder="Link"
    //         onChange={onLinkChange}
    //         isEditable={isEditable}
    //     />
    //     <br />
    //     {meal?.bundles?.length > 0 ? (
    //         <BundleList
    //             bundles={meal?.bundles}
    //             onDeleteBundle={onDeleteBundle}
    //             isEditable={isEditable}
    //         />
    //     ) : (
    //         <div>No Bundles</div>
    //     )}
    //     <br />
    //     {isEditable ? (
    //         <button onClick={() => setModalOpen(true)}>
    //             Add Ingredient
    //         </button>
    //     ) : null}
    //     {isEditable ? (
    //         <button
    //             role="img"
    //             aria-label="delete"
    //             onClick={() => deleteFlow()}
    //         >
    //             🗑
    //         </button>
    //     ) : null}
    //     <CreateBundleModal
    //         modalOpen={modalOpen}
    //         setModalOpen={setModalOpen}
    //         setMeal={setMeal}
    //         meal={meal}
    //     />
    // </div>
  );
};

export default MealDetailPage;
