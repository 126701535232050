import React from "react";

const DetailPage = ({ title, children }) => {
    return (
        <div
            style={{
                paddingTop: "10px",
                maxWidth: "600px",
                width: "fit-content",
                margin: "auto",
            }}
        >
            {title ? <h1>{title}</h1> : null}
            <div>{children}</div>
        </div>
    );
};

export default DetailPage;
