import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

const BackupView = ({ error, loading, children }) => {
    if (error) {
        return (
            <div style={{ height: "100%" }}>
                <div
                    style={{
                        height: "fit-content",
                        marginTop: "20%",
                        padding: "10px",
                    }}
                >
                    <h2>Oops!</h2>
                    <p>
                        It looks like something went wrong. Please refresh the
                        page or try again soon.
                    </p>
                    <p>Sorry for the inconvenience!</p>
                </div>
            </div>
        );
    }

    if (loading) {
        return (
            <Dimmer active inverted>
                <Loader size="large">Loading</Loader>
            </Dimmer>
        );
    }

    return children;
};

export default BackupView;
