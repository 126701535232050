import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getMealPlanPreferenceIngredients } from "../../../../api/OtterApi";
import ProductMapperItem from "./ProductMapperItem";

const ProductMapper = ({
  mealPlanPrefId,
  productMapping,
  setProductMapping,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [mealPlanPrefIngredients, setMealPlanPrefIngredients] = useState([]);

  useEffect(() => {
    const instantiateIngredients = async () => {
      const token = await getAccessTokenSilently();
      const response = await getMealPlanPreferenceIngredients(
        token,
        mealPlanPrefId
      );
      if (response.status === 200) {
        setMealPlanPrefIngredients(response.data.ingredients);
      } else {
        console.error(response.data.error);
      }
    };
    instantiateIngredients();
  }, [getAccessTokenSilently, mealPlanPrefId]);

  //   useEffect(() => {
  //     const instantiateUserDefaultProductMappings = async () => {
  //       const token = await getAccessTokenSilently();
  //       const response = await getMealPlanPreferenceIngredients(
  //         token,
  //         mealPlanPrefId
  //       );
  //       if (response.status === 200) {
  //         setMealPlanPrefIngredients(response.data.ingredients);
  //       } else {
  //         console.error(response.data.error);
  //       }
  //     };
  //     instantiateUserDefaultProductMappings();
  //   }, []);

  return (
    <div style={{ marginTop: "10px" }}>
      {mealPlanPrefIngredients.map((ingredient, index) => {
        return (
          <ProductMapperItem
            ingredient={ingredient}
            productMapping={productMapping}
            setProductMapping={setProductMapping}
            key={ingredient.id}
          />
        );
      })}
    </div>
  );
};

export default ProductMapper;
