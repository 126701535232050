import React, { useState, useEffect } from "react";
import DetailPage from "../general/DetailPage";
import IngredientDetails from "./IngredientDetails";
import { getIngredient, deleteIngredient } from "../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";
import ModifierButtons from "../../components/ModifierButtons";
import { Loader } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useNotificationContext } from "../../contexts/NotificationContext";

const IngredientDetailPage = ({ match }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [ingredient, setIngredient] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const history = useHistory();
  const { addNotification } = useNotificationContext();

  const onEdit = async () => {
    history.push("edit/" + match.params.id);
  };

  const onDelete = async () => {
    const token = await getAccessTokenSilently();
    setIsDeleting(true);
    const response = await deleteIngredient(token, match.params.id);
    if (response.status === 200) {
      addNotification({
        message: "Ingredient deleted successfully.",
      });
      history.push("/ingredient");
    } else {
      console.error(response);
      addNotification({
        message: "Ingredient deletion failed.",
      });
    }
    setIsDeleting(false);
  };

  useEffect(() => {
    const initializeIngredient = async () => {
      const token = await getAccessTokenSilently();
      setIsLoading(true);
      const response = await getIngredient(token, match.params.id);

      if (response.status === 200) {
        setIngredient(response.data.ingredient);
        setIsEditable(response.data.editable);
      } else {
        console.error(response);
      }
      setIsLoading(false);
    };
    initializeIngredient();
  }, []);

  return (
    <div>
      <DetailPage>
        {isLoading ? (
          <Loader active size="medium">
            Loading
          </Loader>
        ) : (
          <>
            <IngredientDetails ingredient={ingredient} />
            {isEditable ? (
              <ModifierButtons
                onEdit={onEdit}
                onDelete={onDelete}
                isDeleting={isDeleting}
                resourceId={match.params.id}
              />
            ) : null}
          </>
        )}
      </DetailPage>
    </div>
  );
};

export default IngredientDetailPage;
