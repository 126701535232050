import React from "react";
import "./Result.css";
import { useHistory } from "react-router-dom";

const Result = ({
    result,
    newResource = false,
    resourceName = "resource",
    match,
}) => {
    const history = useHistory();

    if (newResource) {
        var plusStyle = {
            color: "lightgrey",
            fontSize: "50px",
            // position: "relative",
            marginBottom: "20px",
            // top: "85px",
        };
        return (
            <div
                className="result-container"
                onClick={() =>
                    history.push(history.location.pathname + "/create")
                }
            >
                <div className="result-new-card-container">
                    <div className="result-new-card-plus" style={plusStyle}>
                        +
                    </div>
                </div>
                <div className="result-new-card-name">
                    Create a new {resourceName}
                </div>
            </div>
        );
    } else {
        return (
            <div
                className="result-container"
                onClick={(event) => {
                    if (event.ctrlKey || event.metaKey) {
                        window.open(
                            window.location.href + "/" + result.id,
                            "_blank"
                        );
                    } else {
                        history.push(
                            history.location.pathname + "/" + result.id
                        );
                    }
                }}
            >
                <div>{result.name}</div>
                <div>{result.description}</div>
            </div>
        );
    }
};

export default Result;
