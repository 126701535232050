// This function is used to map the ingredients list from the
// backend api to a list of drop down menu options
export const getIngredientDropdownOptions = (ingredients) => {
  return ingredients.map((ingredient) => {
    return {
      key: ingredient.id,
      value: ingredient,
      label: ingredient.name,
    };
  });
};
