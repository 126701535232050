import React from "react";

const NutritionField = ({ color, isLast, title, value }) => {
    const spaceBetween = "5px";
    return (
        <div
            style={{
                borderStyle: "solid",
                borderColor: `rgba(${color}, 1)`,
                borderWidth: "1px",
                borderRadius: "3px",
                width: "100%",
                height: "30px",
                margin: "0px auto",
                display: "flex",
                flexDirection: "column",
                marginTop: spaceBetween,
                marginBottom: isLast ? spaceBetween : "0px",
            }}
        >
            <div
                style={{
                    color: "grey",
                    fontSize: "10px",
                    width: "100%",
                    textAlign: "left",
                    padding: "0px 3px",
                }}
            >
                {title}
            </div>
            <div
                style={{
                    fontSize: "13px",
                    position: "relative",
                    top: "-12px",
                }}
            >
                {value}
            </div>
        </div>
    );
};

export default NutritionField;
