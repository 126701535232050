import React, { useState, useEffect } from "react";
import "./MealPlanDetailPage.css";
import EditLabel from "../../../components/EditLabel";
import {
  getMealPlan,
  updateMealPlan,
  deleteMealPlan,
  addPlannedMealPreferenceBatch,
  deletePlannedMealPreference,
  getCurrentMealPlan,
  setCurrentMealPlan,
  searchMealPlanPreferences,
  deleteMealPlanPreference,
  getMealPlanPreferenceNutrition,
  updateMealPlanPreference,
} from "../../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";
import CreatePlannedMealModal from "./CreatePlannedMealModal";
import ManageProductsModal from "./productmapping/ManageProductsModal";
import { useHistory } from "react-router-dom";
import MealCard from "./MealCard";
import * as FiIcons from "react-icons/fi";
import * as IoIcons from "react-icons/io";
import PreferenceSelector from "../../general/PreferenceSelector";
import NutritionSection from "./NutritionSection";
import BackupView from "../../../components/BackupView";
import { useNotificationContext } from "../../../contexts/NotificationContext";

const MealPlanDetailPage = ({ match }) => {
  const mealplanId = match.params.id;
  const daysOfTheWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const [mealplan, setMealPlan] = useState();
  const [mealPlanPreferences, setMealPlanPreferences] = useState();
  const [currentMealplanId, setCurrentMealPlanId] = useState();
  const [description, setDescription] = useState();
  const [sortedPlannedMealPrefs, setSortedPlannedMealPrefs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState();
  const [showNutrition, setShowNutrition] = useState(false);
  const [nutrition, setNutrition] = useState();
  const [mealNutritionMap, setMealNutritionMap] = useState({});
  const [flow, setFlow] = useState("loading");
  const { addNotification } = useNotificationContext();

  const preferences =
    mealPlanPreferences?.sort((a, b) => {
      return a?.name > b?.name ? 1 : -1;
    }) || [];

  const onDeletePlannedMealPref = async (
    plannedMealPrefId,
    setPendingDeletion
  ) => {
    const token = await getAccessTokenSilently();
    const response = await deletePlannedMealPreference(
      token,
      selectedPreference.id,
      plannedMealPrefId
    );

    if (response.status === 200) {
      setSelectedPreference(response.data.mealplanpreference);

      instantiateMealPlanPreferences();
      addNotification({
        message: "Planned meal deleted successfully.",
      });
    } else {
      console.error(response);
      setFlow("error");
      addNotification({
        message: "Planned meal failed to delete.",
      });
    }

    setPendingDeletion(false);
  };

  useEffect(() => {
    if (mealPlanPreferences && !selectedPreference) {
      setSelectedPreference(mealPlanPreferences[0]);
    }
  }, [mealPlanPreferences, selectedPreference]);

  useEffect(() => {
    const initializeCurrentMealplan = async () => {
      const token = await getAccessTokenSilently();
      const response = await getCurrentMealPlan(token);
      if (response.status === 200) {
        setCurrentMealPlanId(response.data.mealplan_id);
      } else {
        console.error(response.data.error);
        setFlow("error");
      }
    };
    initializeCurrentMealplan();
  }, [getAccessTokenSilently]);

  const instantiateMealPlanPreferences = async () => {
    const token = await getAccessTokenSilently();
    const mealPlanPrefsResponse = await searchMealPlanPreferences(
      token,
      20,
      0,
      "",
      mealplanId
    );
    if (mealPlanPrefsResponse.status === 200) {
      setMealPlanPreferences(mealPlanPrefsResponse.data.mealplanpreferences);
    } else {
      console.error(mealPlanPrefsResponse);
      setFlow("error");
    }
  };

  useEffect(() => {
    const initializeMealPlan = async () => {
      const token = await getAccessTokenSilently();
      const response = await getMealPlan(token, mealplanId);
      if (response.status === 200) {
        setMealPlan(response.data.mealplan);
        setIsEditable(response.data.editable);

        instantiateMealPlanPreferences();
        setFlow("mealplan");
      } else {
        console.error(response);
        setFlow("error");
      }
    };
    initializeMealPlan();
  }, [getAccessTokenSilently, mealplanId]);

  useEffect(() => {
    if (selectedPreference) {
      setSortedPlannedMealPrefs(
        sortPlannedMealPrefs(selectedPreference.plannedmeal_preferences)
      );

      const instantiateMealPlanPreferenceNutrition = async () => {
        const token = await getAccessTokenSilently();
        const response = await getMealPlanPreferenceNutrition(
          token,
          selectedPreference.id
        );
        if (response.status === 200) {
          setNutrition(response.data.nutrition);
          setMealNutritionMap(response.data.meal_nutrition);
        } else {
          console.error("Failed to get mealplan nutritional information");
        }
      };

      instantiateMealPlanPreferenceNutrition();
    }
  }, [selectedPreference, getAccessTokenSilently]);

  useEffect(() => {}, [description, isEditable]);

  // Sort based on time
  const sortPlannedMealPrefs = (plannedMealPrefs) => {
    return plannedMealPrefs?.sort((a, b) =>
      a.time > b.time ? 1 : a.time === b.time ? (a.time > b.time ? 1 : -1) : -1
    );
  };

  const getHeader = (title) => {
    return (
      <div className="mealplan-detail-header">
        <h1>
          <EditLabel
            value={title?.trim() || "No title."}
            onChange={(newName) => {
              if (newName && newName !== mealplan.name) {
                const copy = { ...mealplan };
                copy.name = newName;
                updateResource({ name: newName });
              }
            }}
            isEditable={isEditable}
          />
        </h1>
      </div>
    );
  };

  const updateResource = async (newResource, fallback = () => {}) => {
    const token = await getAccessTokenSilently();
    const { data, status } = await updateMealPlan(token, {
      id: mealplan.id,
      ...newResource,
    });
    if (status === 200) {
      setMealPlan(data.mealplan);
      addNotification({
        message: "Meal plan updated successfully.",
      });
    } else {
      addNotification({
        message: "Meal plan update failed.",
      });
      setFlow("error");
      fallback();
    }
  };

  const onDescriptionChange = async (newDescription) => {
    if (newDescription && newDescription !== mealplan.description) {
      setDescription(newDescription);
      updateResource(
        {
          description: newDescription,
        },
        () => setDescription(mealplan.description)
      );
    }
  };

  const getDescription = () => {
    return (
      <div className="mealplan-detail-description">
        <h4>Description</h4>

        <EditLabel
          value={description?.trim() || "No description."}
          placeholder="Description"
          onChange={onDescriptionChange}
          isEditable={isEditable}
        />
      </div>
    );
  };

  const onAddPlannedMealPrefs = async (newPlannedMealPrefs) => {
    const token = await getAccessTokenSilently();
    const response = await addPlannedMealPreferenceBatch(
      token,
      selectedPreference.id,
      newPlannedMealPrefs
    );
    if (response.status === 200) {
      setSelectedPreference(response.data.mealplanpreference);
      instantiateMealPlanPreferences();
      addNotification({
        message: "Planned meal(s) added successfully.",
      });
    } else {
      console.error(response);
      setFlow("error");
      addNotification({
        message: "Planned meal(s) failed to be added.",
      });
    }
    setModalOpen(false);
  };

  const onSaveProductMapping = async (productMapping) => {
    if (!productMapping) {
      console.error("productMapping was empty");
      return;
    }

    let productIds = [];
    for (const key in productMapping) {
      if (productMapping[key] !== null) {
        productIds = [productMapping[key]?.id, ...productIds];
      }
    }

    const token = await getAccessTokenSilently();

    // const payload = {
    //   id: selectedPreference?.id,
    //   product_mapping: {
    //     planned_products: productIds,
    //   },
    // };
    // const response = await updateMealPlanPreference(token, payload);

    const payload = {
      id: selectedPreference?.id,
      product_mapping: {
        planned_products: productIds,
      },
    };
    const response = await updateMealPlanPreference(token, payload);

    if (response.status === 200) {
      setProductModalOpen(false);
      addNotification({
        message: "Meal plan preferences updated successfully.",
      });
    } else {
      console.error(response);
      addNotification({
        message: "Meal plan update failed.",
      });
    }
  };

  const deleteResource = async () => {
    const token = await getAccessTokenSilently();
    const response = await deleteMealPlan(token, match.params.id);
    if (response.status === 200) {
      history.push("/mealplan");
      addNotification({
        message: "Meal plan deleted successfully.",
      });
    } else {
      console.error(response);
      setFlow("error");
      addNotification({
        message: "Meal plan deletion failed.",
      });
    }
  };

  const getFavoriteEmoji = () => {
    return currentMealplanId === mealplanId ? (
      <IoIcons.IoIosStar />
    ) : (
      <IoIcons.IoIosStarOutline />
    );
  };

  const setMealplanActive = async () => {
    if (mealplan.id === currentMealplanId) {
      return;
    }

    const token = await getAccessTokenSilently();
    const response = await setCurrentMealPlan(token, mealplanId);
    if (response.status === 200) {
      setCurrentMealPlanId(mealplanId);
    }
  };

  const getButtons = () => {
    return (
      <div className="mealplan-detail-peripheral-buttons">
        {isEditable ? (
          <>
            <div
              className="mealplan-detail-create-plannedmeal-button"
              onClick={() => setModalOpen(true)}
            >
              <FiIcons.FiPlus />
            </div>
            <div
              className="mealplan-detail-create-plannedmeal-button"
              onClick={() => setProductModalOpen(true)}
            >
              <FiIcons.FiLayers />
            </div>
          </>
        ) : null}

        <div
          className="mealplan-detail-set-favorite-button"
          onClick={() => setMealplanActive()}
        >
          {getFavoriteEmoji()}
        </div>
        <div
          className="mealplan-detail-shopping-list-button"
          onClick={() =>
            history.push(
              `/mealplan/${mealplanId}/preference/${selectedPreference.id}/shoppinglist`
            )
          }
        >
          <FiIcons.FiShoppingBag />
        </div>
        {isEditable ? (
          <div
            className="mealplan-detail-delete-mealplan-button"
            onClick={() => deleteResource()}
          >
            <FiIcons.FiTrash2 />
          </div>
        ) : null}
      </div>
    );
  };

  const getCalendarView = () => {
    return (
      <div className="mealplan-detail-calendar-container">
        {daysOfTheWeek.map((day) => {
          return (
            <div key={day} className="mealplan-detail-calendar-column-1">
              {getCalendarColumn({
                day,
                plannedMealPrefs: sortedPlannedMealPrefs?.filter(
                  (plannedMealPref) =>
                    plannedMealPref.day.toLowerCase() === day.toLowerCase()
                ),
              })}
            </div>
          );
        })}
      </div>
    );
  };

  const getCalendarColumn = ({ day, plannedMealPrefs }) => {
    if (!plannedMealPrefs) {
      return;
    }
    return (
      <div className="mealplan-detail-calendar-column-2">
        <div className="mealplan-detail-calendar-column-header">
          {day.substring(0, 3).toUpperCase()}
        </div>
        <NutritionSection
          showNutrition={showNutrition}
          nutrition={nutrition && nutrition[day.toLowerCase()]}
        />
        <div className="mealplan-detail-calendar-column-meal-container">
          {plannedMealPrefs?.map((plannedMealPref) => {
            return (
              <React.Fragment key={plannedMealPref.mealpreference_id + day}>
                <MealCard
                  plannedMealPref={plannedMealPref}
                  onDeletePlannedMealPref={onDeletePlannedMealPref}
                  isEditable={isEditable}
                  showNutrition={showNutrition}
                  nutrition={
                    mealNutritionMap[plannedMealPref.mealpreference_id]
                  }
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const onCreateMealPlanPreference = () => {
    history.push(`/mealplan/${mealplan.id}/preference/create`);
  };

  const onEditMealPlanPreference = (preference) => {
    history.push(`/mealplan/${mealplan.id}/preference/${preference.id}/edit`);
  };

  const onDeleteMealPlanPreference = async (preference) => {
    if (preference.name.toLowerCase() === "default") {
      return;
    }
    const token = await getAccessTokenSilently();
    const response = await deleteMealPlanPreference(token, preference.id);
    if (response.status === 200) {
      setSelectedPreference(
        preferences.filter((pref) => {
          return pref.name.toLowerCase() === "default";
        })[0]
      );
      setMealPlanPreferences(
        mealPlanPreferences.filter((pref) => {
          return pref.id !== preference.id;
        })
      );
    }
  };

  return (
    <BackupView error={flow === "error"} loading={flow === "loading"}>
      <div className="mealplan-detail-container">
        {mealplan ? (
          <div>
            {getHeader(mealplan.name)}
            <div className="mealplan-detail-content-container">
              <div className="mealplan-detail-peripherals">
                {getDescription()}
                {getButtons()}
              </div>
              <div style={{ margin: "0px 12px" }}>
                <div
                  style={{
                    width: "fit-content",
                    marginBottom: "30px",
                  }}
                >
                  <PreferenceSelector
                    preferences={preferences}
                    selectedPreference={selectedPreference}
                    setSelectedPreference={setSelectedPreference}
                    deletePreference={onDeleteMealPlanPreference}
                    editPreference={onEditMealPlanPreference}
                    createPreference={onCreateMealPlanPreference}
                  />
                </div>
                <div
                  style={{
                    borderColor: "lightgrey",
                    borderRadius: "3px",
                    background: "lightgrey",
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    // margin: "0px 10px",
                    padding: "0px 5px",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    setShowNutrition(!showNutrition);
                  }}
                >
                  <div
                    style={{
                      marginTop: "2px",
                    }}
                  >
                    {showNutrition ? (
                      <FiIcons.FiChevronUp />
                    ) : (
                      <FiIcons.FiChevronDown />
                    )}
                  </div>
                  <div
                    style={{
                      margin: "0px 5px",
                      minWidth: "95px",
                    }}
                  >
                    {showNutrition ? "Hide " : "Show "}
                    Nutrition
                  </div>
                </div>
              </div>

              {getCalendarView()}
            </div>

            <CreatePlannedMealModal
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              onAddPlannedMealPrefs={onAddPlannedMealPrefs}
            />
            <ManageProductsModal
              modalOpen={productModalOpen}
              setModalOpen={setProductModalOpen}
              onSaveProductMapping={onSaveProductMapping}
              mealPlanPrefId={selectedPreference?.id}
              mealPlanProductMapping={selectedPreference?.product_mapping}
            />
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    </BackupView>
  );
};

export default MealPlanDetailPage;
