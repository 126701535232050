import React from "react";

import styled from "styled-components";

import { useAuth0 } from "@auth0/auth0-react";
import { Loader } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import { IoLogInSharp, IoLogOutSharp } from "react-icons/io5";

const LoaderDiv = styled.div`
    height: fit-content;
    margin: auto ${(props) => (props.isMobile ? "40px" : "5rem")};
`;

const ButtonDiv = styled.div`
    height: fit-content;
    margin: auto ${(props) => (props.isMobile ? "10px" : "2rem")};
    display: flex;
    color: white;
    font-size: ${(props) => (props.isMobile ? "small" : "medium")};
    font-family: Montserrat, sans-serif;
    flex-direction: row;
    &:hover {
        color: lightgrey;
    }
`;

const LogoDiv = styled.div`
    height: fit-content;
    padding-top: 3px;
    margin-left: 5px;
`;

const AuthenticationButton = ({ loading }) => {
    const { logout, loginWithRedirect, isAuthenticated } = useAuth0();

    if (loading) {
        return (
            <LoaderDiv isMobile={isMobile}>
                <Loader active inverted inline="centered" size="tiny" />
            </LoaderDiv>
        );
    }

    if (isAuthenticated) {
        return (
            <ButtonDiv
                isMobile={isMobile}
                onClick={() =>
                    logout({
                        returnTo: window.location.origin,
                    })
                }
            >
                <div>Sign out</div>
                <LogoDiv>
                    <IoLogOutSharp />
                </LogoDiv>
            </ButtonDiv>
        );
    }

    return (
        <ButtonDiv isMobile={isMobile} onClick={() => loginWithRedirect()}>
            <div>Sign in</div>
            <LogoDiv>
                <IoLogInSharp />
            </LogoDiv>
        </ButtonDiv>
    );
};

export default AuthenticationButton;
