import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./LandingPage.css";
import { getUser } from "../../api/OtterApi";
import { useHistory } from "react-router-dom";

// Landing Page Checklist
/*
    1. Craft a benefit-focused headline.
    2. Choose an image that illustrates the offer
    3. Write compelling copy.
    4. Include the lead form above the fold.
    5. Add a clear and standout call-to-action.
    6. Give away a relevant offer.
    7. Only ask for what you need.
    8. Remove all navigation.
    9. Make your page responsive.
    10. Optimize for search.
    11. Remember to use a thank you page.
*/

// const headline = "Streamlining life management";
const headline = "Life Management Simplified";
//   "LifeBespoked guides you to optimize your daily routine to reach your health goals and reclaim your time";
const description =
  "Life Bespoked streamlines your daily routines and guides you to reach your health goals and reclaim your time.";

const LandingPage = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    const initialize = async () => {
      const token = await getAccessTokenSilently();

      const getUserResponse = await getUser(token);
      if (getUserResponse.status === 404) {
        history.push("/user/signup");
        return;
      } else if (getUserResponse.status === 200) {
        history.push("/dashboard");
      }
    };
    if (isAuthenticated) {
      initialize();
    }
  }, [getAccessTokenSilently, history, isAuthenticated]);

  return (
    <div className="landing-page-container">
      <div className="hero-container">
        <div className="headline">{headline}</div>
        <div className="description">{description}</div>
        <div className="call-to-action">Sign up for a free account</div>
      </div>
    </div>
  );
};

export default LandingPage;
