import React from "react";
import { Divider } from "semantic-ui-react";
import WizardNote from "../wizard/WizardNote";

const WizardCard = ({
    title,
    children,
    forward,
    back,
    finish,
    hasRequirements = true,
    isFirst,
    errorMessage,
    shouldSkip = false,
    dialog,
}) => {
    const buttonStyle = {
        // borderStyle: "solid",
        // borderRadius: "2px",
        // borderWidth: "1px",
        // padding: "3px",
    };
    return (
        <div
            style={{
                height: "fit-content",
                borderRadius: "5px",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "lightgrey",
                padding: "20px",
                margin: "auto",
                textAlign: "left",
            }}
        >
            <div
                style={{ maxWidth: "450px", margin: "auto", marginTop: "20px" }}
            >
                <h3>{title}</h3>
                <div style={{ margin: "20px 0px" }}>
                    {dialog ? <WizardNote text={dialog} /> : null}
                </div>
                <Divider />
                <div
                    style={{
                        // backgroundColor: "red",
                        margin: "auto",
                    }}
                >
                    {children}
                </div>
            </div>
            <div
                style={{
                    color: "red",
                    margin: "auto",
                    marginTop: "20px",
                    textAlign: "center",
                    maxWidth: "300px",
                    minWidth: "200px",
                }}
            >
                {errorMessage}
            </div>
            <div
                style={{
                    display: "flex",
                    width: "20%",
                    margin: "auto",
                    marginTop: "30px",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        ...buttonStyle,
                        color: isFirst ? "lightgrey" : "black",
                    }}
                    onClick={() => {
                        if (!isFirst) {
                            back();
                        }
                    }}
                >
                    Back
                </div>
                |
                <>
                    {finish ? (
                        <div
                            style={buttonStyle}
                            onClick={() => {
                                if (hasRequirements) {
                                    finish();
                                }
                            }}
                        >
                            Save
                        </div>
                    ) : (
                        <div
                            style={{
                                ...buttonStyle,
                                color: hasRequirements ? "black" : "lightgrey",
                            }}
                            onClick={() => {
                                if (hasRequirements) {
                                    forward();
                                }
                            }}
                        >
                            {shouldSkip ? "Skip" : "Next"}
                        </div>
                    )}
                </>
            </div>
        </div>
    );
};

export default WizardCard;
