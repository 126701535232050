import React from "react";
import { createContext, useContext, useState } from "react";

const NotificationContext = createContext();

export const useNotificationContext = () => {
  return useContext(NotificationContext);
};

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = (newNotification) => {
    setNotifications([...notifications, newNotification]);
  };

  return (
    <NotificationContext.Provider value={{ addNotification, notifications }}>
      {children}
    </NotificationContext.Provider>
  );
};
