import React, { useEffect, useState } from "react";
import { GoDot, GoDotFill } from "react-icons/go";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

const stopPropagation = (event) => {
  event.stopPropagation();
};

const CarouselWidget = ({
  title = "Title",
  titles,
  data = [],
  startIndex = 0,
}) => {
  useEffect(() => {
    setIndex(startIndex);
  }, [startIndex]);
  const arrowStyle = {
    width: "45px",
    display: "flex",
    justifyContent: "center",
  };

  const [index, setIndex] = useState(startIndex);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        background: "white",
        borderRadius: "5px",
        overflow: "hidden", // This hides the corners of the children that don't regard partent border radius
        boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.3)",
        animation: "fadeIn 1s",
      }}
    >
      <div
        className="carousel-layout"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          className="carousel-title"
          style={{
            // backgroundColor: "lightpink",
            textAlign: "left",
            paddingLeft: "10px",
            paddingTop: "8px",
            fontSize: "small",
            fontFamily: "Futura,Trebuchet MS,Arial,sans-serif",
            fontWeight: "lighter",
            color: "grey",
          }}
        >
          {titles ? titles[index] : title}
        </div>
        <div
          className="carousel-view"
          style={{
            display: "flex",
            flexDirection: "row",
            // backgroundColor: "lightcoral",
            height: "100%",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className="carousel-left" style={arrowStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                padding: "15px",
                // backgroundColor: "pink",
              }}
              onClick={() => {
                if (index > 0) {
                  setIndex(index - 1);
                }
              }}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
            >
              <FaChevronLeft
                color={index === 0 ? "lightgrey" : "grey"}
                size={"1.5em"}
              />
            </div>
          </div>
          <div className="carousel-content" style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {data[index]}
            </div>
          </div>
          <div className="carousel-right" style={arrowStyle}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                // backgroundColor: "pink",
                padding: "15px",
              }}
              onClick={() => {
                if (index < data.length - 1) {
                  setIndex(index + 1);
                }
              }}
              onMouseDown={stopPropagation}
              onTouchStart={stopPropagation}
            >
              <FaChevronRight
                color={index === data.length - 1 ? "lightgrey" : "grey"}
                size={"1.5em"}
              />
            </div>
          </div>
        </div>
        <div
          className="carousel-navigator"
          style={{
            height: "20px",
            width: "100%",
          }}
        >
          {data.map((_, idx) => {
            if (index === idx) {
              return <GoDotFill color="grey" />;
            } else {
              return <GoDot color="grey" />;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default CarouselWidget;
