import React from "react";

const NutritionSection = ({ nutrition }) => {
    return (
        <div
            // style={{
            //     borderColor: "lightgrey",
            //     borderStyle: "solid",
            //     borderWidth: "1px",
            //     borderRadius: "5px",
            //     padding: "10px",
            //     marginBottom: "10px",
            // }}
            style={{
                display: "flex",
                flexDirection: "row",
                // backgroundColor: "green",
                justifyContent: "center",
                margin: "auto",
                marginTop: "1rem",
                borderColor: "lightgrey",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "5px",
                padding: "10px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    // flexGrow: 1,
                }}
            >
                <div
                    style={{
                        fontWeight: "bold",
                        color: "grey",
                    }}
                >
                    Calories
                </div>
                <div
                    style={{
                        fontSize: "large",
                        marginTop: "5px",
                    }}
                >
                    {nutrition?.calories.toFixed(1) || "n/a"}
                </div>
            </div>
            <div
                style={{
                    borderLeft: "solid 1px lightgrey",
                    margin: "0px 1rem",
                }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    // flexGrow: 1,
                }}
            >
                <div
                    style={{
                        fontWeight: "bold",
                        color: "grey",
                    }}
                >
                    Carbs
                </div>
                <div
                    style={{
                        fontSize: "large",
                        marginTop: "5px",
                    }}
                >
                    {nutrition?.carbohydrates.toFixed(1) || "n/a"}
                </div>
            </div>
            <div
                style={{
                    borderLeft: "solid 1px lightgrey",
                    margin: "0px 1rem",
                }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    // flexGrow: 1,
                }}
            >
                <div
                    style={{
                        fontWeight: "bold",
                        color: "grey",
                    }}
                >
                    Fats
                </div>
                <div
                    style={{
                        fontSize: "large",
                        marginTop: "5px",
                    }}
                >
                    {nutrition?.fats.toFixed(1) || "n/a"}
                </div>
            </div>
            <div
                style={{
                    borderLeft: "solid 1px lightgrey",
                    margin: "0px 1rem",
                }}
            />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                    // flexGrow: 1,
                }}
            >
                <div
                    style={{
                        fontWeight: "bold",
                        color: "grey",
                    }}
                >
                    Proteins
                </div>
                <div
                    style={{
                        fontSize: "large",
                        marginTop: "5px",
                    }}
                >
                    {nutrition?.proteins.toFixed(1) || "n/a"}
                </div>
            </div>
        </div>
    );
};

export default NutritionSection;
