import React from "react";
import { Divider } from "semantic-ui-react";

const ProductDetails = ({ product }) => {
    return (
        <div>
            {product ? (
                <div
                    style={{
                        maxWidth: "600px",
                        width: "fit-content",
                        margin: "auto",
                        marginTop: "2rem",
                        display: "flex",
                        flexWrap: "wrap-reverse",
                        justifyContent: "center",
                    }}
                >
                    <div
                        style={{
                            maxWidth: "400px",
                            textAlign: "left",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                flexDirection: "column",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "xx-large",
                                    lineHeight: "150%",
                                }}
                            >
                                {product.name}
                            </div>
                            <div
                                style={{
                                    fontSize: "medium",
                                    color: "grey",
                                }}
                            >
                                {product.brand}
                            </div>
                        </div>

                        <div
                            style={{
                                fontSize: "large",
                                color: "lightgrey",
                                marginTop: "1rem",
                            }}
                        >
                            {product.description}
                        </div>
                        <Divider />
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                                justifyContent: "flex-start",
                                marginTop: "1rem",
                            }}
                        >
                            <div style={{ fontWeight: "bold" }}>
                                Serving Size:
                            </div>
                            <div style={{ margin: "0px 5px" }}>
                                {product.serving_size}
                            </div>
                            <div>{product.units}</div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                // backgroundColor: "green",
                                justifyContent: "flex-start",
                                margin: "auto",
                                marginTop: "1rem",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    // flexGrow: 1,
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        color: "grey",
                                    }}
                                >
                                    Calories
                                </div>
                                <div
                                    style={{
                                        fontSize: "large",
                                        marginTop: "5px",
                                    }}
                                >
                                    {product.nutrition.calories}
                                </div>
                            </div>
                            <div
                                style={{
                                    borderLeft: "solid 1px lightgrey",
                                    margin: "0px 1rem",
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    // flexGrow: 1,
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        color: "grey",
                                    }}
                                >
                                    Carbs
                                </div>
                                <div
                                    style={{
                                        fontSize: "large",
                                        marginTop: "5px",
                                    }}
                                >
                                    {product.nutrition.carbohydrates}
                                </div>
                            </div>
                            <div
                                style={{
                                    borderLeft: "solid 1px lightgrey",
                                    margin: "0px 1rem",
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    // flexGrow: 1,
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        color: "grey",
                                    }}
                                >
                                    Fats
                                </div>
                                <div
                                    style={{
                                        fontSize: "large",
                                        marginTop: "5px",
                                    }}
                                >
                                    {product.nutrition.fats}
                                </div>
                            </div>
                            <div
                                style={{
                                    borderLeft: "solid 1px lightgrey",
                                    margin: "0px 1rem",
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    textAlign: "center",
                                    // flexGrow: 1,
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: "bold",
                                        color: "grey",
                                    }}
                                >
                                    Proteins
                                </div>
                                <div
                                    style={{
                                        fontSize: "large",
                                        marginTop: "5px",
                                    }}
                                >
                                    {product.nutrition.proteins}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            maxWidth: "400px",
                            minWidth: "300px",
                            marginTop: "1rem",
                            // backgroundColor: "red",
                            paddingBottom: "2rem",
                        }}
                    >
                        <div
                            style={{
                                borderRadius: "4px",
                                borderColor: "lightgrey",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                height: "200px",
                                width: "200px",
                                backgroundImage: `url(https://picsum.photos/200/200?blur=10&random=${
                                    Math.floor(Math.random() * 1000) + 1
                                })`,
                                margin: "auto",
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div style={{ color: "red" }}>
                    There was a problem loading this product, please try again
                    soon.
                </div>
            )}
        </div>
    );
};

export default ProductDetails;
