import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  createMeal,
  deleteMeal,
  createMealPreference,
  getMeal,
  getMealPreference,
  updateMeal,
} from "../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";
import WizardPage from "../wizard/WizardPage";
import WizardCard from "../wizard/WizardCard";
import WizardTextField from "../wizard/WizardTextField";
import WizardFloatField from "../wizard/WizardFloatField";
import WizardTextView from "../wizard/WizardTextView";
import WizardBundleManager from "../wizard/WizardBundleManager";
import WizardBundleList from "../wizard/WizardBundleList";
import WizardProductManager from "../wizard/WizardProductManager";
import { Divider } from "semantic-ui-react";

const MealCreatePage = ({ match }) => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [data, setData] = useState({
    name: "",
    description: "",
    link: "",
    suggested_servings: 1,
    bundles: [],
  });
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const mealId = match.params.id;

  useEffect(() => {
    const instantiateExistingMeal = async () => {
      const token = await getAccessTokenSilently();
      const getMealResponse = await getMeal(token, mealId);
      if (getMealResponse.status === 200) {
        setData(getMealResponse.data.meal);
      } else {
        console.error(getMealResponse);
      }
    };
    if (mealId) {
      instantiateExistingMeal();
    }
  }, []);

  const saveMeal = async () => {
    if (saving) return;
    setSaving(true);
    const token = await getAccessTokenSilently();

    if (mealId) {
      const updateMealResponse = await updateMeal(token, {
        id: mealId,
        name: data.name,
        description: data.description,
        link: data.link,
      });

      if (updateMealResponse.status === 200) {
        history.push("/meal/" + mealId);
      } else {
        console.error(updateMealResponse);
      }
    } else {
      const createMealResponse = await createMeal(token, {
        name: data.name,
        description: data.description,
        link: data.link,
      });

      if (createMealResponse.status === 201) {
        const mealId = createMealResponse.data.meal.id;
        function uuidv4() {
          return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (
              c ^
              (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
            ).toString(16)
          );
        }

        // // Modify the entered bundles to consider the enteres servings.
        // // We only want to store the bundle values for a single serving, so multiplying
        // // by servings will be simple in the future.
        // for (let index = 0; index < data.bundle_prefs?.length || 0; index++) {
        //   const bundle = data.bundle_prefs[index];
        //   bundle.quantity = bundle.quantity / data.suggested_servings;
        // }
        const mealPrefPayload = {
          meal_id: mealId,
          name: "Default",
          bundle_prefs: data.bundles.map((bundle) => {
            return {
              id: uuidv4().toString(),
              ingredient_id: bundle.ingredient.id,
              product_id: bundle.product?.id,
              quantity: parseFloat(bundle.quantity / data.suggested_servings),
              units: bundle.units,
            };
          }),
          suggested_servings: parseFloat(data.suggested_servings),
        };
        const createMealPrefResponse = await createMealPreference(
          token,
          mealPrefPayload
        );

        if (createMealPrefResponse.status === 201) {
          history.push("/meal/" + mealId);
          // Update meal with default preference
          const updateMealResponse = await updateMeal(token, {
            default_pref: createMealPrefResponse.data.mealpreference.id,
            id: mealId,
          });
          if (updateMealResponse.status !== 200) {
            console.error(
              "Failed to update default meal preference of meal: " + mealId
            );
          }
        } else {
          const response = await deleteMeal(token, mealId);
          if (response.status !== 200) {
            console.error(response.data.error);
          }
          setErrorMessage(createMealPrefResponse.data.error);
        }
      } else {
        setErrorMessage(createMealResponse.data.error);
      }
    }

    setSaving(false);
  };

  const shouldSkipBundleCard = () => {
    return data?.bundles?.length === 0;
  };

  const shouldSkipProductCard = () => {
    return (
      data?.bundles?.filter((bundle) => {
        return bundle.product;
      }).length === 0
    );
  };

  const getPageTitle = () => {
    return mealId ? "Edit Meal" : "Create Meal";
  };

  return (
    <WizardPage title={getPageTitle()} data={data} setData={setData}>
      <WizardCard
        title="Details"
        setData={setData}
        hasRequirements={
          data?.name?.trim() && (mealId || data?.suggested_servings >= 1)
        }
      >
        <WizardTextField
          title="Name"
          placeholder="Carne Asada"
          value={data.name}
          onChange={(e) => {
            const eventValue = e.target.value;
            const newName =
              eventValue.charAt(0).toUpperCase() + eventValue.slice(1);
            setData({ ...data, name: newName });
          }}
          required={true}
          max={100}
        />
        <WizardTextField
          title="Description"
          placeholder="Authentic mexican recipe"
          value={data.description}
          required={false}
          onChange={(e) => {
            setData({
              ...data,
              description: e.target.value,
            });
          }}
        />
        <WizardTextField
          title="Link"
          placeholder="http://example.com/carne-asada"
          value={data.link}
          required={false}
          onChange={(e) => {
            setData({
              ...data,
              link: e.target.value,
            });
          }}
        />
        {!mealId && (
          <WizardFloatField
            title="Recommended Servings"
            placeholder="1"
            value={data.suggested_servings}
            required={true}
            onChange={(e) => {
              setData({
                ...data,
                suggested_servings: e.target.value,
              });
            }}
            step="1.0"
          />
        )}
      </WizardCard>
      {!mealId && (
        <WizardCard
          title="Add Ingredients"
          setData={setData}
          errorMessage={errorMessage}
          shouldSkip={shouldSkipBundleCard()}
        >
          <div
            style={{
              fontStyle: "italic",
              textAlign: "center",
              margin: "20px",
              fontSize: "14px",
            }}
          >
            <p>Add ingredients for {data.suggested_servings} servings.</p>
          </div>
          <WizardBundleManager data={data} setData={setData} />
        </WizardCard>
      )}

      <WizardCard
        title="Confirm Meal"
        finish={saveMeal}
        errorMessage={errorMessage}
      >
        <div>
          <h4>Details</h4>
        </div>
        <WizardTextView title="Name" value={data.name} />
        <WizardTextView title="Description" value={data.description || "N/A"} />
        <WizardTextView title="Link" value={data.link || "N/A"} />
        <WizardTextView title="Servings" value={data.suggested_servings} />
        {!mealId && (
          <>
            <Divider />
            <div>
              <h4>Ingredients</h4>
            </div>
            <div
              style={{
                marginTop: "20px",
                padding: "10px",
              }}
            >
              <WizardBundleList bundles={data?.bundles || []} />
            </div>
          </>
        )}
      </WizardCard>
    </WizardPage>
  );
};

export default MealCreatePage;
