import React, { useState, useEffect } from "react";
import { listMealPlans } from "../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";

import SearchBar from "../../components/searchbar/SearchBar";
import "./MealPlanPage.css";
import SearchResultGallery from "../../components/searchbar/gallery/SearchResultGallery";
import * as GrIcons from "react-icons/gr";
import BackupView from "../../components/BackupView";

const MealPlanPage = () => {
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [mealPlans, setMealPlans] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [flow, setFlow] = useState("loading");
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const getMealPlans = async () => {
      const token = await getAccessTokenSilently();
      const response = await listMealPlans(
        token,
        limit,
        offset,
        searchText.trim()
      );
      if (response.status === 200) {
        const rawMealPlans = response.data.mealplans;
        if (rawMealPlans.length === 0) {
          setOffset(0);
        } else {
          setMealPlans(rawMealPlans);
        }
        setFlow("mealplan");
      } else {
        console.error(response.data.error);
        setFlow("error");
      }
    };
    getMealPlans();
  }, [searchText, limit, offset]);

  useEffect(() => {
    const checkHasNext = async () => {
      const token = await getAccessTokenSilently();
      const response = await listMealPlans(
        token,
        1,
        offset + limit + 1,
        searchText.trim()
      );
      if (response.status === 200) {
        const rawMealPlans = response.data.mealplans;
        setHasNext(rawMealPlans && rawMealPlans.length > 0);
      } else {
        console.error(response.data.error);
      }
    };
    checkHasNext();
  }, [mealPlans]);

  const handlePrevClick = () => {
    setOffset(Math.max(0, offset - limit));
  };

  const handleNextClick = () => {
    setOffset(offset + limit);
  };

  return (
    <BackupView error={flow === "error"} loading={flow === "loading"}>
      <div className="mealplan-page-container">
        <SearchBar searchText={searchText} setSearchText={setSearchText} />
        <SearchResultGallery
          title={"Meal Plans"}
          resource={"meal plan"}
          results={mealPlans}
          isMealplan={true}
        />
        <div className="mealplan-search-page-controls">
          <div className="page-prev-button" onClick={handlePrevClick}>
            {offset > 0 ? <GrIcons.GrPrevious /> : ""}
          </div>
          <div className="page-next-button" onClick={handleNextClick}>
            {hasNext ? <GrIcons.GrNext /> : ""}
          </div>
        </div>
      </div>
    </BackupView>
  );
};

export default MealPlanPage;
