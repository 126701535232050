// Number is rounded to a maximum of 3 decimal places if number contains more than 3 sig figs after the decimal place
export function smartRound(number) {
  return Math.round((number + Number.EPSILON) * 1000) / 1000;
}

export function isTimeInFuture(inputTime) {
  // Get the current date and time
  const currentDate = new Date();

  // Extract the hours and minutes from the input time string - "HH:MM"
  const [inputHours, inputMinutes] = inputTime.split(":").map(Number);

  // Create a new date object for the input time on the current date
  const inputDateTime = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate(),
    inputHours,
    inputMinutes
  );

  // Compare the input time with the current time
  return inputDateTime > currentDate;
}
