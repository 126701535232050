import React, { useState, useEffect } from "react";
import MealBundle from "./MealBundle";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {
    getMealPreferenceNutrition,
    deleteMealPreference,
} from "../../api/OtterApi";
import PreferenceSelector from "../general/PreferenceSelector";
import NutritionSection from "../../components/NutritionSection";

const MealDetails = ({ meal, mealPrefs, setMealPrefs }) => {
    const { getAccessTokenSilently } = useAuth0();
    const history = useHistory();
    const [selectedPreference, setSelectedPreference] = useState(
        mealPrefs ? mealPrefs[0] : undefined
    );
    const [nutrition, setNutrition] = useState();
    const preferences = mealPrefs.sort((a, b) => {
        return a?.name > b?.name ? 1 : -1;
    });

    useEffect(() => {
        const instantiate = async () => {
            const token = await getAccessTokenSilently();
            const response = await getMealPreferenceNutrition(
                token,
                selectedPreference.id
            );
            if (response.status === 200) {
                setNutrition(response.data.nutrition);
            }
        };
        if (selectedPreference) {
            instantiate();
        }
    }, [selectedPreference]);

    const onCreateMealPreference = () => {
        history.push(`/meal/${meal.id}/preference/create`);
    };

    const onEditMealPreference = (preference) => {
        history.push(`/meal/${meal.id}/preference/${preference.id}/edit`);
    };

    const onDeleteMealPreference = async (preference) => {
        if (preference.name.toLowerCase() === "default") {
            return;
        }
        const token = await getAccessTokenSilently();
        const response = await deleteMealPreference(token, preference.id);
        if (response.status === 200) {
            setSelectedPreference(
                preferences.filter((pref) => {
                    return pref.name.toLowerCase() === "default";
                })[0]
            );
            setMealPrefs(
                mealPrefs.filter((pref) => {
                    return pref.id !== preference.id;
                })
            );
        }
    };

    const ingredientsList = (
        <div>
            {selectedPreference?.bundle_prefs.map((bundle_pref) => {
                return (
                    <MealBundle
                        bundle_pref={bundle_pref}
                        servings={selectedPreference.suggested_servings}
                    />
                );
            })}
        </div>
    );

    const noIngredientsMessage = (
        <div
            style={{
                fontSize: "small",
                fontStyle: "italic",
                color: "grey",
                marginBottom: "1rem",
            }}
        >
            <p>No ingredients have been added yet.</p>
        </div>
    );

    return (
        <div>
            {meal ? (
                <>
                    <div
                        style={{
                            fontSize: "xx-large",
                            lineHeight: "150%",
                            // backgroundColor: "pink",
                            paddingBottom: "10px",
                            // textAlign: "left"
                        }}
                    >
                        {meal.name}
                    </div>
                    {meal?.description && (
                        <div
                            style={{
                                fontSize: "large",
                                color: "lightgrey",
                                marginBottom: "1rem",
                            }}
                        >
                            {meal.description}
                        </div>
                    )}
                    {meal?.link && (
                        <div style={{ marginBottom: "10px" }}>
                            <a
                                href={meal.link}
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                Link to recipe
                            </a>
                        </div>
                    )}
                    <div
                        style={{
                            borderRadius: "4px",
                            borderColor: "lightgrey",
                            borderStyle: "solid",
                            borderWidth: "1px",
                            height: "400px",
                            width: "400px",
                            backgroundImage: `url(https://picsum.photos/400/400?blur=10&random=${
                                Math.floor(Math.random() * 1000) + 1
                            })`,
                            margin: "auto",
                        }}
                    />
                    <NutritionSection
                        nutrition={selectedPreference?.nutrition}
                    />
                    <div
                        className="meal-detail-bottom-container"
                        style={{
                            // backgroundColor: "lightblue",
                            height: "fit-content",
                            width: "100%",
                        }}
                    >
                        <PreferenceSelector
                            preferences={preferences}
                            selectedPreference={selectedPreference}
                            setSelectedPreference={setSelectedPreference}
                            deletePreference={onDeleteMealPreference}
                            editPreference={onEditMealPreference}
                            createPreference={onCreateMealPreference}
                        />
                        <div
                            style={{
                                borderColor: "lightgrey",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                borderRadius: "5px",
                                padding: "10px",
                                marginBottom: "10px",
                            }}
                        >
                            <h4
                                className="ui header"
                                style={{ textAlign: "left" }}
                            >
                                Ingredients
                            </h4>
                            {selectedPreference?.bundle_prefs?.length > 0
                                ? ingredientsList
                                : noIngredientsMessage}
                        </div>
                        <div
                            style={{
                                borderColor: "lightgrey",
                                borderStyle: "solid",
                                borderWidth: "1px",
                                borderRadius: "5px",
                                padding: "10px",
                                marginBottom: "10px",
                                textAlign: "left",
                            }}
                        >
                            <h4
                                class="ui header"
                                style={{
                                    textAlign: "left",
                                    display: "inline",
                                    paddingRight: "0.5rem",
                                }}
                            >
                                Recommended Servings:
                            </h4>
                            {selectedPreference?.suggested_servings}
                        </div>
                    </div>
                </>
            ) : (
                <div style={{ color: "red" }}>
                    There was a problem loading this ingredient, please try
                    again soon.
                </div>
            )}
        </div>
    );
};

export default MealDetails;
