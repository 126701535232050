import React, { useState, useEffect } from "react";
import WizardDropDown from "./WizardDropDown";
import WizardFloatField from "./WizardFloatField";
import WizardBundlePrefList from "./WizardBundlePrefList";
import { SUPPORTED_UNITS_OPTIONS } from "../general/Constant";
import { uuidv4 } from "../../utils/uuid";
import { Divider } from "semantic-ui-react";

import { useAuth0 } from "@auth0/auth0-react";

import { listIngredients } from "../../api/OtterApi";

const WizardBundleManager = ({ data, setData }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [selectedIngredient, setSelectedIngredient] = useState();
    const [selectedAlternatives, setSelectedAlternatives] = useState([]);
    const [ingredientOptions, setIngredientOptions] = useState([]);
    const [ingredientQuery, setIngredientQuery] = useState("");
    const [quantity, setQuantity] = useState();
    const [units, setUnits] = useState("");

    useEffect(() => {
        const getIngredients = async () => {
            const token = await getAccessTokenSilently();
            const response = await listIngredients(
                token,
                10,
                0,
                ingredientQuery
            );
            if (response.status === 200) {
                setIngredientOptions(
                    getIngredientDropdownOptions(response.data.ingredients)
                );
            }
        };
        getIngredients();
    }, [ingredientQuery]);

    const clearFields = () => {
        setSelectedAlternatives([]);
        setSelectedIngredient("");
        setQuantity("");
        setUnits("");
    };

    const removeBundle = (index) => {
        const bundlePrefs = data.bundle_prefs;
        bundlePrefs.splice(index, 1);
        var dataCopy = {
            ...data,
            bundle_prefs: bundlePrefs,
        };
        setData(dataCopy);
    };

    const addBundle = () => {
        const newBundlePref = {
            id: uuidv4().toString(),
            ingredient_id: selectedIngredient.id,
            product_id: "",
            alternatives: selectedAlternatives,
            quantity: parseFloat(quantity),
            units,
        };

        const bundlePrefs = data.bundle_prefs;
        var dataCopy = {
            ...data,
            bundle_prefs: [...bundlePrefs, newBundlePref],
        };
        setData(dataCopy);
        clearFields();
    };

    const hasRequiredFields = () => {
        return (
            selectedIngredient &&
            quantity &&
            parseFloat(quantity) > 0 &&
            units &&
            units.length > 0
        );
    };

    const getIngredientDropdownOptions = (ingredients) => {
        return ingredients.map((ingredient) => {
            return {
                key: ingredient.id,
                value: ingredient,
                text: ingredient.name,
            };
        });
    };

    return (
        <div style={{ margin: "auto" }}>
            <WizardDropDown
                title="Ingredient"
                placeholder="Select Ingredient"
                required={true}
                options={ingredientOptions}
                setSearchQuery={setIngredientQuery}
                setValue={setSelectedIngredient}
                value={selectedIngredient}
            />
            <WizardFloatField
                title="Quantity"
                required={true}
                placeholder="2"
                value={quantity}
                onChange={(e) => {
                    setQuantity(e.target.value);
                }}
                max={100}
            />
            <WizardDropDown
                title="Units"
                placeholder="Tablespoons"
                required={true}
                options={SUPPORTED_UNITS_OPTIONS}
                setSearchQuery={(filterValue) => {
                    return SUPPORTED_UNITS_OPTIONS.filter((option) =>
                        option.value
                            .toLowerCase()
                            .includes(filterValue.toLowerCase())
                    );
                }}
                setValue={(value) => {
                    setUnits(value);
                }}
                value={units}
                // hasRequirements={data.ingredient}
            />
            <div
                style={{
                    width: "100%",
                }}
            >
                <div
                    style={{
                        width: "fit-content",
                        margin: "auto",
                        marginTop: "20px",
                        color: hasRequiredFields() ? "black" : "grey",
                    }}
                >
                    <Divider horizontal inverted>
                        <div
                            style={{
                                position: "relative",
                                top: "10px",
                            }}
                        >
                            <button
                                // className="ui button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (hasRequiredFields()) {
                                        addBundle();
                                    }
                                }}
                            >
                                Add Ingredient
                            </button>
                        </div>
                    </Divider>
                </div>
            </div>
            <div style={{ marginTop: "30px" }}>
                <h4>Current Ingredients</h4>
            </div>
            <div
                style={{
                    marginTop: "20px",
                    // borderStyle: "solid",
                    // borderWidth: "1px",
                    // borderRadius: "3px",
                }}
            >
                <WizardBundlePrefList
                    bundlePrefs={data.bundle_prefs}
                    removeBundle={removeBundle}
                />
            </div>
        </div>
    );
};

export default WizardBundleManager;
