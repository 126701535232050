import { isMobile } from "react-device-detect";
import {
  getMealPlanPreferenceShoppingList,
  getUserPreferences,
  clearShoppingList,
} from "../../api/OtterApi";
import React, { useState, useEffect } from "react";
import ShoppingListCard from "./shoppinglistcard/ShoppingListCard";
import InventoryCard from "./inventorycard/InventoryCard";
import styled from "styled-components";
import { useAuth0 } from "@auth0/auth0-react";
import InventoryNote from "./inventorynote/InventoryNote";
import BackupView from "../../components/BackupView";
import { INGREDIENT_CATEGORIES } from "../general/Constant";
import { useNotificationContext } from "../../contexts/NotificationContext";
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Icon,
  Modal,
} from "semantic-ui-react";

const development = false;
const SLContainer = styled.div`
  ${development ? "background: gainsboro;" : ""}
  max-width: 500px;
  width: 100%;
  height: fit-content;
  margin: 0px ${isMobile ? "0px" : "auto"};
  padding: ${isMobile ? 16 : 20}px ${isMobile ? 16 : 20}px;
  display: flex;
  flex-direction: column;
`;

const HeaderSection = styled.div`
  height: fit-content;
  font-size: ${isMobile ? 20 : 25}px;
  line-height: ${isMobile ? 20 : 25}px;
  font-weight: bold;
  margin-top: auto;
`;

const SecondaryHeaderSection = styled.div`
  ${"" /* background: pink; */}
  font-size: ${isMobile ? 18 : 23}px;
  line-height: ${isMobile ? 18 : 23}px;
  font-weight: bold;
  margin-top: auto;
  color: grey;
`;

const HeaderRow = styled.div`
  ${"" /* background: lightblue; */}
  display: flex;
  flex-direction: row;
  height: 100%;
  width: ${isMobile ? 210 : 260}px;
  justify-content: space-between;
  margin-bottom: 10px;
  ${"" /* padding-bottom: 10px; */}
`;

const CategoryHeader = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
  color: grey;
  text-align: left;
`;

const ShoppingListPage = ({ match }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [shoppingList, setShoppingList] = useState();
  const [flow, setFlow] = useState("loading");
  const { getAccessTokenSilently } = useAuth0();
  const [multiplier, setMultiplier] = useState();
  const [preferences, setPreferences] = useState();
  const [seed, setSeed] = useState(1);
  const { addNotification } = useNotificationContext();

  const refresh = () => {
    setSeed(Math.random());
  };

  useEffect(() => {
    const instantiatePreferences = async () => {
      const token = await getAccessTokenSilently();
      const response = await getUserPreferences(token);
      if (response.status === 200) {
        setPreferences(response.data.preferences);
        setFlow("success");
      } else {
        console.error(response.data.error);
        setFlow("error");
      }
    };
    instantiatePreferences();
  }, []);

  useEffect(() => {
    if (preferences) {
      setMultiplier(preferences.default_multiplier);
    }
  }, [preferences]);

  useEffect(() => {}, [multiplier]);

  useEffect(() => {
    const instantiateShoppingList = async () => {
      const token = await getAccessTokenSilently();
      const getShoppingListResponse = await getMealPlanPreferenceShoppingList(
        token,
        match.params.prefId
      );
      if (getShoppingListResponse.status === 200) {
        setShoppingList(
          getShoppingListResponse?.data.bundlepreferences.sort((a, b) =>
            a.name > b.name ? 1 : -1
          )
        );
        setFlow("shopping");
      } else {
        console.error(getShoppingListResponse);
        setFlow("error");
      }
    };
    instantiateShoppingList();
  }, [getAccessTokenSilently, match, seed]);

  const getShoppingListHeader = () => {
    const shoppingListText = "Shopping List";
    return flow === "shopping" ? (
      <HeaderSection>{shoppingListText}</HeaderSection>
    ) : (
      <SecondaryHeaderSection
        onClick={() => {
          setFlow("shopping");
        }}
      >
        {shoppingListText}
      </SecondaryHeaderSection>
    );
  };

  const getInventoryHeader = () => {
    const inventoryText = "Inventory";
    return flow === "inventory" ? (
      <HeaderSection>{inventoryText}</HeaderSection>
    ) : (
      <SecondaryHeaderSection
        onClick={() => {
          setFlow("inventory");
        }}
      >
        {inventoryText}
      </SecondaryHeaderSection>
    );
  };

  const getMultiplierField = () => {
    return (
      <input
        type="number"
        value={multiplier}
        step="1"
        onChange={(e) => setMultiplier(e.target.value)}
      />
    );
  };

  const ClearInventoryButton = styled.div`
    margin: auto;
    font-weight: bold;
    color: white;
    background-color: #ca3433;
    padding: 0.5rem;
    border-radius: 20px;
    border-color: #ca3433;
    border-width: 2px;
    border-style: solid;
    width: 100%;
    margin-top: 1rem;

    &:hover {
      color: #ca3433;
      background-color: white;
      border-radius: 20px;
      border-color: #ca3433;
      border-width: 2px;
      border-style: solid;
    }
  `;

  const onClearInventory = async () => {
    const token = await getAccessTokenSilently();
    const response = await clearShoppingList(token);

    let notificationMessage = "Failed to clear inventory.";
    if (response.status === 200) {
      notificationMessage = "Successfully cleared inventory.";
      // Refresh page
      refresh();
    }
    addNotification({
      message: notificationMessage,
    });
    setConfirmOpen(false);
  };

  return (
    <BackupView error={flow === "error"} loading={flow === "loading"}>
      <SLContainer>
        <HeaderRow>
          {getShoppingListHeader()}
          {getInventoryHeader()}
        </HeaderRow>

        {flow === "inventory" && <InventoryNote />}
        {getMultiplierField()}
        {shoppingList &&
          INGREDIENT_CATEGORIES.map((category) => {
            const filtered_ingredients = shoppingList.filter((bundle) => {
              return (
                // Includes invalid categories as "other"
                (category === "Other" &&
                  (!bundle.category || bundle.category === "")) ||
                bundle.category === category
              );
            });
            if (!filtered_ingredients || filtered_ingredients.length === 0) {
              return null;
            }

            return (
              <>
                <CategoryHeader>{category}</CategoryHeader>
                {filtered_ingredients.map((bundle) => {
                  return (
                    <>
                      {flow === "shopping" && (
                        <ShoppingListCard
                          bundle={bundle}
                          multiplier={multiplier}
                        />
                      )}
                      {flow === "inventory" && (
                        <InventoryCard
                          bundle={bundle}
                          multiplier={multiplier}
                        />
                      )}
                    </>
                  );
                })}
              </>
            );
          })}
        <ClearInventoryButton onClick={() => setConfirmOpen(true)}>
          Clear Inventory
        </ClearInventoryButton>
      </SLContainer>
      <Modal size={"mini"} open={confirmOpen}>
        <ModalHeader>Clear Inventory</ModalHeader>
        <ModalContent>
          <p>Are you sure you want to clear the inventory?</p>
        </ModalContent>
        <ModalActions>
          <Button negative onClick={() => setConfirmOpen(false)}>
            No
          </Button>
          <Button positive onClick={() => onClearInventory()}>
            Yes
          </Button>
        </ModalActions>
      </Modal>
    </BackupView>
  );
};

export default ShoppingListPage;
