import React from "react";
import WizardCard from "../../../wizard/WizardCard";
import WizardTextField from "../../../wizard/WizardTextField";
import WizardFloatField from "../../../wizard/WizardFloatField";

const MealPrefDetailsCard = ({
  back,
  forward,
  isFirst,
  wizData,
  setWizData,
}) => {
  return (
    <WizardCard
      title="Details"
      setData={setWizData}
      hasRequirements={wizData?.name?.trim()}
      back={back}
      forward={forward}
      isFirst={isFirst}
    >
      <WizardTextField
        title="Name"
        placeholder="New Meal Preference"
        value={wizData.name}
        onChange={(e) => {
          const eventValue = e.target.value;
          const newName =
            eventValue.charAt(0).toUpperCase() + eventValue.slice(1);
          setWizData({ ...wizData, name: newName });
        }}
        required={true}
        max={100}
      />
      <WizardFloatField
        title="Servings"
        placeholder="1"
        value={wizData.suggested_servings}
        required={true}
        onChange={(e) => {
          setWizData({
            ...wizData,
            suggested_servings: parseFloat(e.target.value),
          });
        }}
      />
    </WizardCard>
  );
};

export default MealPrefDetailsCard;
