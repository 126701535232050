import React, { useState, useEffect } from "react";
import {
  getMeal,
  listMeals,
  searchMealPreferences,
} from "../../../api/OtterApi";
import DaySelector from "../../../components/DaySelector";
import SearchableDropdown from "../../../components/SearchableDropdown";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import PreferenceSelector from "../../general/PreferenceSelector";

const CreatePlannedMealModal = ({
  modalOpen,
  setModalOpen,
  onAddPlannedMealPrefs,
}) => {
  const [meals, setMeals] = useState([]);
  const [mealPreferences, setMealPreferences] = useState([]);
  const [selectedPreference, setSelectedPreference] = useState();
  const [plannedMealDays, setPlannedMealDays] = useState([]);
  const [plannedMealTime, setPlannedMealTime] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [dropdownSelected, setDropdownSelected] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMeal, setSelectedMeal] = useState();

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setSelectedPreference(mealPreferences ? mealPreferences[0] : undefined);
  }, [mealPreferences]);

  useEffect(() => {
    const initiateMeals = async () => {
      const token = await getAccessTokenSilently();
      const getMealsResponse = await listMeals(token, 30, 0, searchQuery);

      if (getMealsResponse.status === 200) {
        setMeals(getMealsResponse.data.meals);
      }
    };

    initiateMeals();
  }, [searchQuery]);

  useEffect(() => {
    if (meals.length > 0) {
      setDropdownOptions(
        meals.map((meal) => {
          const option = {
            value: meal,
            label: meal.name,
          };
          return option;
        })
      );
    }
  }, [meals]);

  useEffect(() => {
    if (dropdownOptions.length > 0) {
      setDropdownSelected(dropdownOptions[0]);
    }
  }, [dropdownOptions]);

  useEffect(() => {
    const instantiateMealPreferences = async () => {
      const token = await getAccessTokenSilently();
      const searchMealPrefsResponse = await searchMealPreferences(
        token,
        100,
        0,
        "",
        dropdownSelected.value.id
      );

      if (searchMealPrefsResponse.status === 200) {
        setMealPreferences(searchMealPrefsResponse.data.mealpreferences);
      }
    };
    if (dropdownSelected?.value?.id) {
      instantiateMealPreferences();
    }
  }, [dropdownSelected]);

  useEffect(() => {
    if (selectedPreference) {
      const instantiateSelectedMeal = async () => {
        const token = await getAccessTokenSilently();
        const getMealResponse = await getMeal(
          token,
          selectedPreference.meal_id
        );

        if (getMealResponse.status === 200) {
          setSelectedMeal(getMealResponse.data.meal);
        }
      };

      instantiateSelectedMeal();
    }
  }, [selectedPreference]);

  const onPlannedMealCreate = () => {
    // Don't allow the creation of meals without the required fields.
    if (
      !plannedMealTime ||
      plannedMealDays.length === 0 ||
      !dropdownSelected.value.id
    )
      return;

    const plannedMealPrefs = [];

    plannedMealDays.forEach((day) => {
      plannedMealPrefs.push({
        mealpreference_id: selectedPreference.id,
        day,
        time: plannedMealTime,
      });
    });
    onAddPlannedMealPrefs(plannedMealPrefs);
  };

  const onDaysToggled = (days) => {
    setPlannedMealDays(days);
  };

  return (
    <div>
      <Modal
        closeIcon
        dimmer={"blurring"}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
      >
        <Header icon="food" content="Add Meals" />
        <Modal.Content>
          <div>
            <SearchableDropdown
              options={dropdownOptions}
              selected={dropdownSelected}
              onSelectedChange={setDropdownSelected}
              setSearchQuery={setSearchQuery}
              label="Meal"
            />
            <PreferenceSelector
              preferences={mealPreferences}
              selectedPreference={selectedPreference}
              setSelectedPreference={setSelectedPreference}
            />
            <div
              className="recommended-servings-container"
              style={{
                height: "fit-content",
                width: "100%",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "5px",
                borderColor: "lightgrey",
                margin: "10px 0px",
                padding: "10px",
                marginBottom: "15px",
              }}
            >
              <h4 style={{ fontWeight: "normal" }}>
                Recommended Servings: {selectedPreference?.suggested_servings}
              </h4>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  width: "fit-content",
                  marginRight: "20px",
                }}
              >
                <div style={{ marginBottom: "10px" }}>Select Time</div>
                <input
                  placeholder="Time"
                  type="time"
                  value={plannedMealTime}
                  onChange={(e) => {
                    setPlannedMealTime(e.target.value);
                  }}
                />
              </div>
              <div>
                <div style={{ marginBottom: "10px" }}>Select Days</div>
                <DaySelector onDaysToggled={onDaysToggled} initial />
              </div>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={() => onPlannedMealCreate(false)}>
            <Icon name="add to calendar" /> Add Meal(s)
          </Button>
          <Button color="red" onClick={() => setModalOpen(false)}>
            <Icon name="cancel" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default CreatePlannedMealModal;
