import React, { useState, useEffect } from "react";

const FillingProgressBar = ({ duration }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const startTime = Date.now();

    const updateProgress = () => {
      const elapsedTime = Date.now() - startTime;
      const percentage = (elapsedTime / duration) * 100;

      // Ensure the percentage doesn't exceed 100
      const newProgress = Math.min(percentage, 100);
      setProgress(newProgress);

      // Continue updating the progress until the duration is reached
      if (elapsedTime < duration) {
        requestAnimationFrame(updateProgress);
      }
    };

    // Start updating the progress
    updateProgress();

    // Cleanup function to reset the progress when the component unmounts
    return () => setProgress(0);
  }, []);

  if (!duration) {
    return null;
  }
  return (
    // <div style={{ width: "100%" }}>
    <div
      style={{
        width: `${progress}%`,
        height: "2px",
        backgroundColor: "#3498db",
        transition: "width 0s",
      }}
    />
    // </div>
  );
};

export default FillingProgressBar;
