import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import "./UserProductMappingPage.css";

import {
    createProductFromUpc,
    getUserDefaultProductMappings,
    listIngredients,
    searchProducts,
    saveProductMapping,
    getProduct,
} from "../../api/OtterApi";
import SearchableDropdown from "../../components/SearchableDropdown";
import { getIngredientDropdownOptions } from "../../components/ingredient.js";
import { getProductDropdownOptions } from "../../components/product.js";
import UpcScanner from "./../product/UpcScanner.js";

import { PiBarcode } from "react-icons/pi";
import {
    ModalHeader,
    ModalContent,
    ModalActions,
    Button,
    Modal,
} from "semantic-ui-react";

function exampleReducer(state, action) {
    switch (action.type) {
        case "OPEN_MODAL":
            return { open: true, dimmer: action.dimmer };
        case "CLOSE_MODAL":
            return { open: false };
        default:
            throw new Error();
    }
}

const getUserProductMappings = async (token, setProductMappings) => {
    const response = await getUserDefaultProductMappings(token, []);
    if (response.status === 200) {
        const rawProductMappings = response.data.product_mappings;
        return setProductMappings(rawProductMappings);
    } else {
        console.error(response.data.error);
    }
};

const getIngredients = async (token, setIngredients, ingredientIds) => {
    const response = await listIngredients(token, 100, 0, "", ingredientIds);
    if (response.status === 200) {
        const rawIngredients = response.data.ingredients;
        return setIngredients(rawIngredients);
    } else {
        console.error(response.data.error);
    }
};

const getProducts = async (token, setProducts, productIds) => {
    const response = await searchProducts(token, 100, 0, "", productIds);
    if (response.status === 200) {
        const rawProducts = response.data.products;
        return setProducts(rawProducts);
    } else {
        console.error(response.data.error);
    }
};

const UserProductMappingPage = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [message, setMessage] = useState();
    const [productMappings, setProductMappings] = useState([]);

    // These ingredients are held in memory as a quick reference to the name of an
    // ingredient which is part of the product mappings.
    const [ingredients, setIngredients] = useState([]);

    // These products are held in memory as a quick reference to the name of an
    // product which is part of the product mappings.
    const [products, setProducts] = useState([]);

    const [ingredientSearchQuery, setIngredientSearchQuery] = useState("");
    const [selectedIngredient, setSelectedIngredient] = useState();
    const [ingredientOptions, setIngredientOptions] = useState([]);

    const [productSearchQuery, setProductSearchQuery] = useState("");
    const [selectedProduct, setSelectedProduct] = useState();
    const [productOptions, setProductOptions] = useState([]);

    const [upcScannerProduct, setUpcScannerProduct] = useState();

    const instantiateUserProductMappings = async () => {
        const token = await getAccessTokenSilently();
        await getUserProductMappings(token, setProductMappings);
    };

    // Get ProductMappings
    useEffect(() => {
        instantiateUserProductMappings();
    }, []);

    // Upon completion of the UPC scan
    useEffect(() => {
        const saveUpcScannedProductAndSetProductDropdown = async () => {
            // Close the UPC scanner modal
            dispatch({ type: "CLOSE_MODAL" });
            console.log("Closing modal");
            console.log(upcScannerProduct);

            setSelectedProduct({
                key: upcScannerProduct?.id || "UPC Product Error",
                value: upcScannerProduct || "UPC Product Error",
                label: upcScannerProduct?.name || "UPC Product Error",
            });

            setUpcScannerProduct(null);
            // instantiateUserProductMappings();
        };

        if (upcScannerProduct) {
            saveUpcScannedProductAndSetProductDropdown();
        }
    }, [upcScannerProduct]);

    // Get Ingredients - Used for rendering product mappings seperate from the drop down menu
    useEffect(() => {
        if (productMappings?.length > 0) {
            const instantiateIngredients = async () => {
                const token = await getAccessTokenSilently();
                const ingredientIds = productMappings.map((productMapping) => {
                    return productMapping.ingredient_id;
                });

                if (ingredientIds?.length > 0) {
                    getIngredients(token, setIngredients, ingredientIds);
                } else {
                    setIngredients([]);
                }
            };
            instantiateIngredients();
        }
    }, [productMappings]);

    // Get Products - Used for rendering product mappings seperate from the drop down menu
    useEffect(() => {
        if (productMappings?.length > 0) {
            const instantiateProducts = async () => {
                const token = await getAccessTokenSilently();
                const productIds = productMappings.map((productMapping) => {
                    return productMapping.product_id;
                });

                if (productIds?.length > 0) {
                    getProducts(token, setProducts, productIds);
                } else {
                    setProducts([]);
                }
            };
            instantiateProducts();
        }
    }, [productMappings]);

    // Get Ingredient Options
    useEffect(() => {
        const instantiateIngredientDropdownOptions = async () => {
            const token = await getAccessTokenSilently();
            const response = await listIngredients(
                token,
                100,
                0,
                ingredientSearchQuery,
                []
            );
            if (response.status === 200) {
                const rawIngredients = response.data.ingredients;
                setIngredientOptions(
                    getIngredientDropdownOptions(rawIngredients)
                );
            } else {
                console.error(response.data.error);
            }
        };
        instantiateIngredientDropdownOptions();
    }, [ingredientSearchQuery]);

    // Get Product Options
    useEffect(() => {
        const instantiateProductDropdownOptions = async () => {
            const token = await getAccessTokenSilently();
            const response = await searchProducts(
                token,
                100,
                0,
                productSearchQuery
            );
            if (response.status === 200) {
                const rawProducts = response.data.products;
                setProductOptions(getProductDropdownOptions(rawProducts));
            } else {
                console.error(response.data.error);
            }
        };
        instantiateProductDropdownOptions();
    }, [productSearchQuery]);

    const onSaveProductMapping = async () => {
        const token = await getAccessTokenSilently();
        if (!selectedIngredient?.value?.id || !selectedProduct?.value?.id) {
            console.error("ingredient and product must be selected.");
            console.log(selectedIngredient);
            console.log(selectedProduct);
            return;
        }
        saveProductMapping(
            token,
            selectedIngredient.value.id,
            selectedProduct.value.id,
            true
        );

        // TODO: Check to see if save was successful
        instantiateUserProductMappings();
    };

    const productMappingData = productMappings
        ?.map((productMapping) => {
            const ingredient = ingredients?.filter((ingredient) => {
                // console.log(ingredient.id);
                // console.log(productMapping?.ingredient_id);
                // console.log(productMapping?.ingredient_id == ingredient.id);
                return productMapping?.ingredient_id === ingredient.id;
            })[0];
            const product = products?.filter((product) => {
                // console.log(product.id);
                // console.log(productMapping?.product_id);
                // console.log(productMapping?.product_id == product.id);
                return productMapping?.product_id === product.id;
            })[0];
            return {
                productName: product?.name,
                productId: product?.id,
                ingredientName: ingredient?.name,
                ingredientId: ingredient?.id,
            };
        })
        .sort((a, b) => {
            return a?.ingredientName?.localeCompare(b?.ingredientName);
        });

    const selectedIngredientName = ingredients?.filter((ingredient) => {
        // console.log(ingredient.id);
        // console.log(selectedIngredient);
        return selectedIngredient?.id === ingredient.id;
    })[0]?.name;

    const [state, dispatch] = React.useReducer(exampleReducer, {
        open: false,
        dimmer: undefined,
    });
    const { open, dimmer } = state;

    return (
        <div className="product-page-container">
            <Modal
                style={{}}
                dimmer={dimmer}
                open={open}
                onClose={() => dispatch({ type: "CLOSE_MODAL" })}
            >
                <ModalHeader>Scan Product</ModalHeader>
                <ModalContent>
                    Scan the product's barcode which you would like to use for
                    the following ingredient: {selectedIngredientName?.name}
                    <UpcScanner setProduct={setUpcScannerProduct} />
                </ModalContent>
                <ModalActions>
                    <Button
                        negative
                        onClick={() => dispatch({ type: "CLOSE_MODAL" })}
                    >
                        Exit
                    </Button>
                </ModalActions>
            </Modal>
            <h1>Product Mapping Page</h1>
            {/* {productMappings?.map((productMapping, i) => {
        return (
          
        );
      })} */}
            <div
                style={{
                    backgroundColor: "whitesmoke",
                    borderRadius: "25px",
                    padding: "10px",
                }}
            >
                <SearchableDropdown
                    options={ingredientOptions}
                    selected={selectedIngredient}
                    onSelectedChange={setSelectedIngredient}
                    setSearchQuery={setIngredientSearchQuery}
                    label={"Ingredient"}
                />
                <SearchableDropdown
                    options={productOptions}
                    selected={selectedProduct}
                    onSelectedChange={setSelectedProduct}
                    setSearchQuery={setProductSearchQuery}
                    label={"Product"}
                />
                {/* {JSON.stringify(productMapping)} */}
                <br></br>
                <PiBarcode
                    size={"2em"}
                    onClick={() => dispatch({ type: "OPEN_MODAL" })}
                />
                <br></br>
                <button
                    class="ui button"
                    onClick={onSaveProductMapping}
                    isLoading={false}
                >
                    Save Product Mapping
                </button>
            </div>
            <h1>Product Mappings</h1>
            {productMappingData?.map((productMappingDatum) => {
                return (
                    <div
                        style={{
                            backgroundColor: "whitesmoke",
                            borderRadius: "25px",
                            padding: "10px",
                            marginBottom: "10px",
                        }}
                    >
                        <div
                            className="product-mapping-list-item-container"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <div
                                className="product-mapping-list-item-content"
                                style={{
                                    display: "flex",
                                    width: "100%",
                                    //   backgroundColor: "blue",
                                    flexDirection: "row",
                                    //   justifyContent: "space-between",
                                }}
                            >
                                <div
                                    className="product-mapping-list-item-ingredient"
                                    style={{
                                        fontSize: "12px",
                                        width: "40%",
                                        textAlign: "left",
                                        color: "blue",
                                    }}
                                    onClick={() => {
                                        console.log(productMappingDatum);
                                        window.open(
                                            "/ingredient/" +
                                                productMappingDatum?.ingredientId,
                                            "_blank"
                                        );
                                    }}
                                >
                                    {productMappingDatum?.ingredientName ||
                                        "n/a"}
                                </div>
                                <div style={{ width: "20%" }}>{"<>"}</div>
                                <div
                                    className="product-mapping-list-item-product"
                                    style={{
                                        fontSize: "12px",
                                        width: "40%",
                                        textAlign: "left",
                                        color: "blue",
                                    }}
                                    onClick={() => {
                                        window.open(
                                            "/product/" +
                                                productMappingDatum?.productId,
                                            "_blank"
                                        );
                                    }}
                                >
                                    {productMappingDatum?.productName || "n/a"}
                                </div>
                            </div>
                            {/* <div
                className="product-mapping-list-item-close-button"
                style={{ color: "red" }}
                onClick={}
              >
                Delete
              </div> */}
                        </div>
                    </div>
                );
                // return JSON.stringify(productMapping);
            })}
            {/* <h1>Ingredients</h1>
      {ingredients?.map((ingredient) => {
        return JSON.stringify(ingredient);
      })} */}
        </div>
    );
};

export default UserProductMappingPage;
