import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getIngredient } from "../../api/OtterApi";
import { Link } from "react-router-dom";
import { unitValueToText } from "../../pages/general/Constant";
import { smartRound } from "../../utils/general";

const MealBundle = ({ bundle_pref: bundlePref, servings }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [ingredient, setIngredient] = useState();

  useEffect(() => {
    const instantiate = async () => {
      const token = await getAccessTokenSilently();
      const getIngredientResponse = await getIngredient(
        token,
        bundlePref.ingredient_id
      );
      if (getIngredientResponse.status === 200) {
        setIngredient(getIngredientResponse.data.ingredient);
      }
    };
    if (bundlePref) {
      instantiate();
    }
  }, [bundlePref]);

  return (
    <div
      style={{
        textAlign: "left",
        borderColor: "lightgrey",
        borderStyle: "solid",
        borderRadius: "5px",
        borderWidth: "1px",
        padding: "5px",
        width: "fit-content",
        margin: "5px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            marginRight: "5px",
          }}
        >
          {smartRound(bundlePref.quantity * servings)}
        </div>
        {unitValueToText(bundlePref.units)}
        <div
          style={{
            margin: "0px 10px",
            color: "grey",
          }}
        >
          of
        </div>
        <Link
          className="ui link"
          style={{ marginRight: "10px", color: "black" }}
          to={ingredient ? `/ingredient/${ingredient.id}` : "#"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {ingredient?.name || <div class="ui tiny active inline loader"></div>}
        </Link>
      </div>
    </div>
  );
};

export default MealBundle;
