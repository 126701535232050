import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  getMealPreference,
  searchMealPreferences,
} from "../../../api/OtterApi";
import WizardPage from "../../wizard/WizardPage";
import MealPrefDetailsCard from "./wizard/1_MealPrefDetailsCard";
import MealPrefIngredientsCard from "./wizard/2_MealPrefIngredientsCard";
import MealPrefConfirmCard from "./wizard/3_MealPrefConfirmCard";

const CreateMealPreferencePage = ({ match }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [preferences, setPreferences] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [wizardData, setWizardData] = useState();

  useEffect(() => {
    const instantiatePreferences = async () => {
      const token = await getAccessTokenSilently();
      const response = await searchMealPreferences(
        token,
        20,
        0,
        "",
        match.params.id
      );
      if (response.status === 200) {
        setPreferences(response.data.mealpreferences);
      } else {
        console.error(response);
      }
    };

    const instantiatePreference = async () => {};
    if (match.params.prefId) {
      setIsEdit(true);
      instantiatePreference();
    } else {
      setIsEdit(false);
      instantiatePreferences();
    }
  }, [getAccessTokenSilently, match]);

  useEffect(() => {
    const instantiateWizData = async () => {
      const token = await getAccessTokenSilently();
      const response = await getMealPreference(token, match.params.prefId);
      if (response.status === 200) {
        const pref = response.data.mealpreference;
        if (pref.meal_id === match.params.id) {
          // Since the bundles stored in the back end are only for a single serving, we need to update the
          // value in this wizard to show what the bundle value should be for all servings.
          for (let index = 0; index < pref.bundle_prefs.length; index++) {
            const bundle = pref.bundle_prefs[index];
            bundle.quantity = bundle.quantity * pref.suggested_servings;
          }
          setWizardData(pref);
        } else {
          console.error("It looks like this meal and preference do not match.");
        }
      } else {
        console.error(response);
      }
    };
    if (isEdit) {
      instantiateWizData();
    } else if (preferences) {
      // Create a default meal preference name
      const defaultPreference = preferences.filter((preference) => {
        return preference.name.toLowerCase() === "default";
      })[0];
      defaultPreference.name = `New Preference ${preferences.length}`;
      setWizardData(defaultPreference);
    }
  }, [preferences, isEdit, getAccessTokenSilently, match]);

  if (!wizardData) {
    return <div>Loading...</div>;
  }

  return (
    <WizardPage
      title={`${isEdit ? "Edit" : "Create"} Meal Preference`}
      data={wizardData}
      setData={setWizardData}
    >
      <MealPrefDetailsCard wizData={wizardData} setWizData={setWizardData} />
      <MealPrefIngredientsCard
        wizData={wizardData}
        setWizData={setWizardData}
      />
      <MealPrefConfirmCard wizData={wizardData} isEdit={isEdit} />
    </WizardPage>
  );
};

export default CreateMealPreferencePage;
