import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  createMealPlan,
  createMealPlanPreference,
  listMealPlans,
} from "../../../api/OtterApi";
import { addDays, format } from "date-fns";
import { useAuth0 } from "@auth0/auth0-react";
import WizardPage from "../../wizard/WizardPage";
import WizardCard from "../../wizard/WizardCard";
import WizardTextField from "../../wizard/WizardTextField";
import WizardTextView from "../../wizard/WizardTextView";
import WizardDropDown from "../../wizard/WizardDropDown";
import { useNotificationContext } from "../../../contexts/NotificationContext";

const calculateDateRangeName = () => {
  const getNextMondayDate = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    if (currentDay === 1) {
      return currentDate;
    } else {
      const daysUntilNextMonday = (8 - currentDay) % 7;
      return addDays(currentDate, daysUntilNextMonday);
    }
  };
  const getSubsequentSundayDate = (mondayDate) => {
    return addDays(mondayDate, 6);
  };

  const nextMonday = getNextMondayDate();
  const subsequentSunday = getSubsequentSundayDate(nextMonday);

  const dateFormat = "yyyy/MM/dd";
  const formattedStartDate = format(nextMonday, dateFormat);
  const formattedEndDate = format(subsequentSunday, dateFormat);

  return `${formattedStartDate} - ${formattedEndDate}`;
};

const MealPlanCreatePage = () => {
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [mealPlan, setMealPlan] = useState({ name: calculateDateRangeName() });
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { addNotification } = useNotificationContext();

  const [template, setTemplate] = useState();
  const [templateOptions, setTemplateOptions] = useState([]);
  const [templateQuery, setTemplateQuery] = useState();

  const getTempalteDropdownOptions = (templates) => {
    return templates.map((template) => {
      return {
        key: template.id,
        value: template,
        text: template.name,
      };
    });
  };

  useEffect(() => {
    const getTemplates = async () => {
      const token = await getAccessTokenSilently();
      const response = await listMealPlans(token, 100, 0, templateQuery);
      if (response.status === 200) {
        setTemplateOptions(getTempalteDropdownOptions(response.data.mealplans));
      }
    };
    getTemplates();
  }, [getAccessTokenSilently, templateQuery]);

  const saveMealPlan = async () => {
    if (saving) return;
    setSaving(true);
    const token = await getAccessTokenSilently();
    const createMealPlanResponse = await createMealPlan(
      token,
      mealPlan,
      template?.id
    );

    if (createMealPlanResponse.status === 201) {
      const mealPlanId = createMealPlanResponse.data.mealplan.id;

      if (!template) {
        const defaultMealPlanPrefPayload = {
          plannedmeal_preferences: [],
          mealplan_id: mealPlanId,
          name: "Default",
        };
        const createMealPlanPreferenceResponse = await createMealPlanPreference(
          token,
          defaultMealPlanPrefPayload
        );
        if (createMealPlanPreferenceResponse.status === 201) {
          addNotification({
            message: "Meal plan saved successfully.",
          });
        } else {
          console.error("Failed to create default preference for mealplan.");
          addNotification({
            message: "Something went wrong while setting up your meal plan.",
          });
        }
      } else {
        addNotification({
          message: "Meal plan created successfully from template.",
        });
      }

      history.push("/mealplan/" + mealPlanId);
    } else {
      setErrorMessage("Failed to create mealplan.");
      addNotification({
        message: "Meal plan creation failed.",
      });
    }

    setSaving(false);
  };

  return (
    <WizardPage title={"Create Meal Plan"}>
      <WizardCard
        title="Details"
        setData={setMealPlan}
        hasRequirements={mealPlan?.name?.trim().length > 0}
      >
        <WizardTextField
          title="Name"
          placeholder="Keto"
          value={mealPlan.name}
          onChange={(e) => {
            const eventValue = e.target.value;
            const newName =
              eventValue.charAt(0).toUpperCase() + eventValue.slice(1);
            setMealPlan({ ...mealPlan, name: newName });
          }}
          required={true}
          max={100}
        />
        <WizardTextField
          title="Description"
          placeholder="Low Carb Diet"
          value={mealPlan.description}
          onChange={(e) => {
            const eventValue = e.target.value;
            const newDescription =
              eventValue.charAt(0).toUpperCase() + eventValue.slice(1);
            setMealPlan({
              ...mealPlan,
              description: newDescription,
            });
          }}
          required={false}
        />
      </WizardCard>
      <WizardCard
        title="Copy from existing meal plan"
        setData={setTemplate}
        hasRequirements={true}
      >
        <WizardDropDown
          title="Meal plan to copy"
          required={false}
          setSearchQuery={setTemplateQuery}
          options={templateOptions}
          setValue={(value) => {
            setTemplate(value);
          }}
          placeholder="Template meal plan"
          value={template}
        />
      </WizardCard>
      <WizardCard
        title="Confirm Meal Plan"
        finish={saveMealPlan}
        errorMessage={errorMessage}
      >
        <div>
          <h4>Details</h4>
        </div>
        <WizardTextView title="Name" value={mealPlan.name} />
        <WizardTextView
          title="Description"
          value={mealPlan.description || "N/A"}
        />
      </WizardCard>
    </WizardPage>
  );
};

export default MealPlanCreatePage;
