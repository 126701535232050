import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";

const WizardDropDown = ({
    title,
    required,
    setSearchQuery,
    options,
    setValue,
    placeholder,
    multiple = false,
    value,
    search = true,
}) => {
    const inputStyle = {
        maxWidth: "500px",
        display: "flex",
        justifyContent: "space-between",
        margin: "5px 10px",
        height: "fit-content",
    };

    const hasRequirements = () => {
        return value;
    };

    useEffect(() => {}, [value, options]);

    return (
        <div className={"ui form"} style={inputStyle}>
            <div
                style={{
                    minWidth: "70px",
                    margin: "auto",
                    marginRight: "0px",
                    textAlign: "right",
                }}
            >
                <label>{title}: </label>
            </div>
            <div
                style={{
                    display: "flex",
                    marginTop: "5px",
                    width: "250px",
                    marginLeft: "30px",
                }}
            >
                <Dropdown
                    style={{
                        borderStyle: "solid",
                        borderColor:
                            required === true
                                ? hasRequirements()
                                    ? "lightgrey"
                                    : "red"
                                : "lightgrey",
                        borderRadius: "3px",
                        borderWidth: "1px",
                    }}
                    placeholder={placeholder}
                    fluid
                    multiple={multiple}
                    search={search}
                    selection
                    options={options}
                    onSearchChange={(e, v) => {
                        setSearchQuery(v.searchQuery);
                    }}
                    onChange={(e, v) => {
                        if (v.value) {
                            setValue(v.value);
                        }
                    }}
                    value={value}
                    onOpen={() => {
                        setSearchQuery && setSearchQuery("");
                    }}
                />

                <div
                    style={{
                        marginLeft: "5px",
                        color: "red",
                        width: "15px",
                    }}
                >
                    {required ? "*" : null}
                </div>
            </div>
        </div>
    );
};

export default WizardDropDown;
