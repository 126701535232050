import React, { useState, useEffect } from "react";
import { getProduct } from "../../../../api/OtterApi";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
import CollapsibleCard from "../../../../components/CollapsibleCard";
import ProductMapper from "./ProductMapper";

const ManageProductsModal = ({
  modalOpen,
  setModalOpen,
  mealPlanPrefId,
  onSaveProductMapping,
  mealPlanProductMapping,
}) => {
  const [productMapping, setProductMapping] = useState([]);
  const { getAccessTokenSilently } = useAuth0();

  //   useEffect(() => {
  //     if (productMapping != {}) {
  //       onSaveProductMapping(productMapping);
  //     }
  //   }, [productMapping]);

  useEffect(() => {
    const instantiateProductMapping = async () => {
      const token = await getAccessTokenSilently();
      const productMappingCopy = [...productMapping];
      for (let i = 0; i < mealPlanProductMapping?.length; i++) {
        console.log(mealPlanProductMapping[i]);
        const response = await getProduct(
          token,
          mealPlanProductMapping[i]?.product_id
        );
        if (response.status === 200) {
          const product = response.data.product;
          productMappingCopy[mealPlanProductMapping[i]?.ingredient_id] =
            product;
        } else {
          console.error(response);
        }
      }
      setProductMapping(productMappingCopy);
    };
    instantiateProductMapping();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, mealPlanProductMapping]);

  return (
    <div>
      <Modal
        closeIcon
        dimmer={"blurring"}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onOpen={() => setModalOpen(true)}
      >
        <Header icon="food" content="Product Mapping" />
        <Modal.Content>
          <CollapsibleCard
            title={"What's a Product Mapping?"}
            background={"#EEEEEE"}
          >
            <p>
              A product mapping allows you to associate a product with one of
              the ingredients in your meal plan.
            </p>
            <p>
              When a product is mapped to your ingredients, this allows us to
              calculate the nutritional value of your meals, as well as your
              daily nutritional intake.
            </p>
          </CollapsibleCard>
          <ProductMapper
            mealPlanPrefId={mealPlanPrefId}
            productMapping={productMapping}
            setProductMapping={setProductMapping}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={() => onSaveProductMapping(productMapping)}
          >
            <Icon name="add to calendar" /> Save Product Mapping
          </Button>
          <Button color="red" onClick={() => setModalOpen(false)}>
            <Icon name="cancel" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ManageProductsModal;
